import { ToastContainer } from 'react-toastify'
import { AppContext, AppProvider } from '../AppContext'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Logout from '../Logout'
import Loading from './Loading'
import NavMenu from './NavMenu'
import Recover from '../pages/Recover'
import LoginForm from '../pages/LoginForm'
import Terms from '../pages/collector/Terms'
import Profile from '../pages/client/Profile'
import Dashboard from '../pages/admin/Dashboard'
import ListUsers from '../pages/admin/ListUsers'
import ViewPoint from '../pages/client/ViewPoint'
import CreateUser from '../pages/admin/CreateUser'
import ListVaults from '../pages/admin/ListVaults'
import ListAgents from '../pages/admin/ListAgents'
import ListPoints from '../pages/client/ListPoints'
import CreateVault from '../pages/admin/CreateVault'
import CreateAgent from '../pages/admin/CreateAgent'
import ViewClient from '../pages/collector/ViewClient'
import ListClients from '../pages/collector/ListClients'
import ListCollectors from '../pages/admin/ListCollectors'
import CreateCollector from '../pages/admin/CreateCollector'
import ViewAppointment from '../pages/client/ViewAppointment'
import ListAppointments from '../pages/client/ListAppointments'
import ListConsolidated from '../pages/client/ListConsolidated'
import CreateAppointment from '../pages/admin/CreateAppointment'
import CompleteAppointment from '../pages/collector/CompleteAppointment'

const App = () => {
    return (
        <BrowserRouter>
            <AppProvider>
                <AppContext.Consumer>
                    {({loading, path}) => (
                        <section className={path+(loading ? ' isLoading' : '')}>
                            <Loading />
                            <NavMenu />
                            <div className="overflow">
                                <Routes>
                                    <Route path="/" exact element={<Dashboard />} />
                                    <Route path="/perfil" exact element={<Profile />} />
                                    <Route path="/sedes" exact element={<ListPoints />} />
                                    <Route path="/bodegas" exact element={<ListVaults />} />
                                    <Route path="/clientes" exact element={<ListClients />} />
                                    <Route path="/cerrar-sesion" exact element={<Logout />} />
                                    <Route path="/sedes/:id" exact element={<ListPoints />} />
                                    <Route path="/sedes/crear" exact element={<ViewPoint />} />
                                    <Route path="/comerciales" exact element={<ListAgents />} />
                                    <Route path="/iniciar-sesion" exact element={<LoginForm />} />
                                    <Route path="/administradores" exact element={<ListUsers />} />
                                    <Route path="/bodegas/crear" exact element={<CreateVault />} />
                                    <Route path="/clientes/crear" exact element={<ViewClient />} />
                                    <Route path="/clientes/:page" exact element={<ListClients />} />
                                    <Route path="/recolectores" exact element={<ListCollectors />} />
                                    <Route path="/sedes/:action/:id" exact element={<ViewPoint />} />
                                    <Route path="/comerciales/crear" exact element={<CreateAgent />} />
                                    <Route path="/recuperar-contrasena/" exact element={<Recover />} />
                                    <Route path="/bodegas/:action/:id" exact element={<CreateVault />} />
                                    <Route path="/recolecciones/" exact element={<ListAppointments />} />
                                    <Route path="/clientes/:action/:id" exact element={<ViewClient />} />
                                    <Route path="/administradores/crear" exact element={<CreateUser />} />
                                    <Route path="/recolectores/crear" exact element={<CreateCollector />} />
                                    <Route path="/comerciales/:action/:id" exact element={<CreateAgent />} />
                                    <Route path="/recuperar-contrasena/:token" exact element={<Recover />} />
                                    <Route path="/recolecciones/:page" exact element={<ListAppointments />} />
                                    <Route path="/recolecciones/crear" exact element={<CreateAppointment />} />
                                    <Route path="/administradores/:action/:id" exact element={<CreateUser />} />
                                    <Route path="/sedes/certificados/:id" exact element={<ListConsolidated />} />
                                    <Route path="/recolectores/:action/:id" exact element={<CreateCollector />} />
                                    <Route path="/recolecciones/:action/:id" exact element={<CreateAppointment />} />
                                    <Route path="/recolecciones/consultar/:id" exact element={<ViewAppointment />} />
                                    <Route path="/recolecciones/crear/:client" exact element={<CreateAppointment />} />
                                    <Route path="/recolecciones/cliente/:client" exact element={<ListAppointments />} />
                                    <Route path="/recolecciones/completar/:id" exact element={<CompleteAppointment />} />
                                    <Route path="/terminos-y-condiciones/:client/:appointment" exact element={<Terms />} />
                                    <Route path="/recolecciones/crear/:client/:point" exact element={<CreateAppointment />} />
                                    <Route path="/recolecciones/cliente/:client/page/:page" exact element={<ListAppointments />} />
                                    <Route path="/recolecciones/cliente/:client/sede/:point" exact element={<ListAppointments />} />
                                    <Route path="/recolecciones/cliente/:client/sede/:point/page/:page" exact element={<ListAppointments />} />
                                </Routes>
                            </div>
                        </section>
                    )}
                </AppContext.Consumer>
                <ToastContainer position="bottom-right" />
            </AppProvider>
        </BrowserRouter>
    )
    //{user === null ? <Logout /> : "" }
    //<Route exact path="/recolecciones" element={<CreateAppointment />} />
    //<Route exact path="/recolecciones/completar" element={<CompleteAppointment />} />
}
export default App