import Popup from '../../layout/Popup'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../../AppContext'
import { appointmentTypes } from '../../layout/Names'
import { useContext, useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

const CompleteAppointment = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [ post, setPost ] = useState(null)
    const [ confirm, setConfirm ] = useState(false)
    const { user, hostBase, sendToPrint } = useContext(AppContext)
    
    const updateField = (e) => setPost({...post, [e.target.name]: e.target.value})
    const doUpdate = (e) => {
        e.preventDefault()
        if(user?.type < 2 || post?.completed <= 0){
            let formData = new FormData()
            formData.append('uid', user.uid)
            for(let key in post)
                formData.append(key, post[key])
            const token = localStorage.getItem('userToken')
            fetch(`${hostBase}/api/appointment/update/?token=${token}`, { method: 'POST', body: formData })
                .then(res => res.json())
                .then((data) => {
                    if(data?.error)
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    else{
                        toast.success(data.state, { theme: "colored", transition: Flip })
                        document.querySelector(".overflow").scrollTop = 0
                        return fetch(`${hostBase}/api/appointment/get/${id}/?token=${token}`)
                    }
                }).then(res => res.json())
                .then((data) => {
                    if(data.length !== 0)
                        setPost(data)
                    else
                        toast.error('No se encontró información para esta cita.', { theme: "colored", transition: Flip })
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar actualizar la cita.', { theme: "colored", transition: Flip }) })
                .finally(() => {})
        }
    }
    const confirmCancell = e => {
        e.preventDefault()
        setConfirm({name: e.target.getAttribute('name')})
    }
    const doCancell = e => {
        e.preventDefault()
        setConfirm(false)
    }
    const cancellAppointment = (e, comments = false) => {
        e.preventDefault()
        if(post?.completed <= 0 || user?.type < 2){
            let formData = new FormData()
            formData.append('aid', id)
            formData.append('comments', comments)
            if(post?.completed > 0)
                formData.append('completed', post?.completed)
            const token = localStorage.getItem('userToken')
            fetch(`${hostBase}/api/appointment/cancell/?token=${token}`, { method: 'POST', body: formData })
                .then(res => res.json())
                .then((data) => {
                    if(data?.error)
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    else{
                        toast.success(data.state, { theme: "colored", transition: Flip })
                        setConfirm(false)
                        return fetch(`${hostBase}/api/appointment/get/${id}/?token=${token}`)
                    }
                }).then(res => res.json())
                .then((data) => {
                    if(data.length !== 0)
                        setPost(data)
                    else
                        toast.error('No se encontró información para esta cita.', { theme: "colored", transition: Flip })
                })
                .catch((e) => { toast.error(`Ocurrió un error al intentar ${user?.type < 2 ? 'anular' : 'cancelar'} la cita.`, { theme: "colored", transition: Flip }) })
                .finally(() => {})
        }
    }
    const getTotal = post => {
        const _price = post?.point_price || post?.price
        if((post?.point_price || post?.price) && post?.quantity)
            return (_price * post?.quantity).toFixed(2)
        return false
    }
    
    useEffect(() => {
        if(post === null && id){
            const token = localStorage.getItem('userToken')
            fetch(`${hostBase}/api/appointment/get/${id}/?token=${token}`)
                .then(res => res.json())
                .then((data) => {
                    if(data.length !== 0){
                        if(data.terms == null)
                            navigate(`/terminos-y-condiciones/${data.client}/${data.aid}/`)
                        else
                            setPost(data)
                    }else
                        toast.error('No se encontró información para esta cita.', { theme: "colored", transition: Flip })
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer la información de la cita.', { theme: "colored", transition: Flip }) })
                .finally(() => {})
        }
    }, [])
    
    return <>
        {confirm ? <Popup title={`${user?.type < 2 ? 'Anular' : 'Cancelar'} recolección`} message={`Está seguro que desea ${user?.type < 2 ? 'anular' : 'cancelar'} la recolección`} action={cancellAppointment} cancel={doCancell} button={`${user?.type < 2 ? 'Anular' : 'Cancelar'} recolección`} comments={true}></Popup> : <></>}
        <form onSubmit={doUpdate}>
            <input type="hidden" name="aid" value={post?.aid || ''} />
            <h2 className={(post?.completed > 0 && 'min') || (post?.cancelled == 1 && 'red') || ''}>{(post?.cancelled == 1 && 'Cita cancelada') || (post?.completed > 0 && 'Cita completada') || 'Completar cita'}.</h2>
            {post?.completed > 0 ? <div className="right">
                <a className="button min inline" onClick={e => {e.preventDefault(); sendToPrint(`${hostBase}/api/appointment/receipt/${id}/?token=${localStorage.getItem('userToken')}`); }}>Recibo</a>
                <a className="button min" target="_blank" href={`${hostBase}/api/appointment/warrant/${id}/?token=${localStorage.getItem('userToken')}`}>Certificado</a>
            </div> : ''}
            <div>
                <label htmlFor="type">Tipo de cita</label>
                <input type="text" name="type" value={appointmentTypes[post?.type] || ''} readOnly disabled />
            </div>
            <div>
                <label htmlFor="client">Cliente</label>
                <input type="text" name="client" value={post?.client_name || ''} readOnly disabled />
            </div>
            <div>
                <label htmlFor="point">Sede</label>
                <input type="text" name="point" value={post?.point_name || ''} readOnly disabled />
            </div>
            {post?.completed > 0 ? <div className="datetime">
                <label htmlFor="date">Fecha</label>
                <input type="date" name="date_completed" value={post?.date_completed || ''} onChange={updateField} required readOnly={user?.type >1 && (post?.completed > 0 || post?.cancelled == 1)} disabled={user?.type >1 && (post?.completed > 0 || post?.cancelled == 1)} />
                <input type="time" name="time_completed" value={post?.time_completed || ''} onChange={updateField} required readOnly={user?.type >1 && (post?.completed > 0 || post?.cancelled == 1)} disabled={user?.type >1 && (post?.completed > 0 || post?.cancelled == 1)} />
                <input type="hidden" name="date" value={post?.date || ''} readOnly disabled />
                <input type="hidden" name="time" value={post?.time || ''} readOnly disabled />
            </div> : <div className="datetime">
                <label htmlFor="date">Fecha</label>
                <input type="date" name="date" value={post?.date || ''} readOnly disabled />
                <input type="time" name="time" value={post?.time || ''} readOnly disabled />
            </div>}
            {post?.cancelled == 0 && <>
                <h3>{post?.completed > 0 || post?.cancelled ? 'Información de la visita' : 'Completar recolección.'}</h3>
                <div>
                    <label htmlFor="completed">Consecutivo</label>
                    <input type="number" name="completed" value={post?.completed > 0 && post?.completed || ''} placeholder="Se asignará al completar" readOnly disabled />
                </div>
                <div>
                    <label htmlFor="who">¿Quién entrega?</label>
                    <input type="text" name="who" value={post?.who || ''} onChange={updateField} required readOnly={user?.type >1 && (post?.completed > 0 || post?.cancelled == 1)} disabled={user?.type >1 && (post?.completed > 0 || post?.cancelled == 1)} />
                </div>
                <div>
                    <label htmlFor="price">Precio por Kg</label>
                    <input type="text" name="price" value={post?.point_price || post?.price || ''} placeholder="0" readOnly disabled />
                </div>
                <div>
                    <label htmlFor="full">Recipientes recibidos</label>
                    <input type="number" name="full" value={post?.full || ''} placeholder="0" onChange={updateField} readOnly={user?.type >1 && (post?.completed > 0 || post?.cancelled == 1)} disabled={user?.type >1 && (post?.completed > 0 || post?.cancelled == 1)} />
                </div>
                <div>
                    <label htmlFor="empty">Recipientes vacíos entregados</label>
                    <input type="number" name="empty" value={post?.empty || ''} placeholder="0" onChange={updateField} readOnly={user?.type >1 && (post?.completed > 0 || post?.cancelled == 1)} disabled={user?.type >1 && (post?.completed > 0 || post?.cancelled == 1)} />
                </div>
                <div>
                    <label htmlFor="quantity">Cantidad recolectada (Kg)</label>
                    <input type="number" name="quantity" value={post?.quantity || ''} placeholder="0" onChange={updateField} required readOnly={user?.type >1 && (post?.completed > 0 || post?.cancelled == 1)} disabled={user?.type >1 && (post?.completed > 0 || post?.cancelled == 1)} />
                </div>
                <div>
                    <label htmlFor="quantity">Precio total</label>
                    <input type="number" name="total_price" value={getTotal(post) || ''} placeholder="0" readOnly disabled />
                </div>
                <div>
                    <label htmlFor="quantity">Método de pago</label>
                    <input type="text" name="payment" value={post?.payment || ''} readOnly disabled />
                </div>
                <div>
                    <label htmlFor="invoice">Factura <small>(Opcional)</small></label>
                    <input type="text" name="invoice" value={post?.invoice || ''} onChange={updateField} readOnly={user?.type >1 && (post?.completed > 0 || post?.cancelled == 1)} disabled={user?.type >1 && (post?.completed > 0 || post?.cancelled == 1)} />
                </div>
            </> || null}
            <div className="full">
                <label htmlFor="comments">Observaciones</label>
                <textarea name="comments" value={post?.comments || ''} onChange={updateField} readOnly={user?.type >1 && (post?.completed > 0 || post?.cancelled == 1)} disabled={user?.type >1 && (post?.completed > 0 || post?.cancelled == 1)}></textarea>
            </div>
            {post?.cancelled == 0 && <>
                {user?.type < 2 && <><a className="button red" onClick={confirmCancell}>Anular certificado</a> <button type="submit">Actualizar recolección</button></>}
                {user?.type == 2 && post?.completed <= 0 && <><a className="button red" onClick={confirmCancell}>Cancelar recolección</a> <button type="submit">Completar recolección</button></> || null}
            </> || null}
        </form>
    </>
}
export default CompleteAppointment