import { useState } from 'react'

const Popup = ({title, message, action, cancel, button = 'Eliminar', comments = false}) => {
    const [ post, setPost ] = useState(null)

    const updateField = e => setPost({...post, [e.target.name]: e.target.value})
    const sendComments = e => {
        e.preventDefault()
        action(e, post?.reason+(post?.comments && ' - '+post?.comments || ''))
        return false
    }

    return <div className="popup">
        <div className="content">
            <h2>{title}</h2>
            <p>{message}</p>
            {comments && <form onSubmit={sendComments}>
                <div className="full left" style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                    <label className="full" htmlFor="reason" style={{fontSize: '14px'}}>Motivo</label>
                    {['Dirección errada', 'No hay aceite', 'Punto cerrado', 'Cliente inactivo', 'Otro'].map((value, i) => <div key={i} className="checkitem"><input type="radio" name="reason" value={value} checked={post?.reason == value} onChange={updateField} required /> {value}</div>)}
                </div>
                <div className="full left">
                    <label htmlFor="comments" style={{fontSize: '14px'}}>Observaciones</label>
                    <textarea name="comments" value={post?.comments || ''} onChange={updateField} style={{borderRadius: '8px', marginBottom: '20px', maxHeight: '80px', padding: '4px 8px'}}></textarea>
                </div>
                <p>
                    <a className="button min gray" onClick={cancel}>Volver</a>
                    <button type="submit" className="button min red">{button}</button>
                </p>
            </form> || <p>
                <a className="button min gray" onClick={cancel}>Volver</a>
                <a className="button min red" onClick={action}>Eliminar</a>
            </p>}
        </div>
    </div>
}
export default Popup