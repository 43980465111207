import { Link } from 'react-router-dom'
import { AppContext } from '../AppContext'
import { toast, Flip } from 'react-toastify'
import { useContext, useState } from 'react'

const LoginForm = () => {
    const [ post, setPost ] = useState({})
    const [ showP, setShowP ] = useState(false)
    const { setLoading, setUser, hostBase } = useContext(AppContext)

    const updateField = (e) => {
        let _post = {...post}
        _post[e.target.name] = e.target.value
        setPost(_post)
    }
    const doLogin = (e) => {
        e.preventDefault()
        setLoading(true)
        let formData = new FormData()
        for(let key in post)
            formData.append(key, post[key])
        fetch(`${hostBase}/api/user/login/`, { method: 'POST', body: formData })
            .then(res => res.json())
            .then( (data) => {
                if(typeof data.error != 'undefined')
                    toast.error(data.error, { theme: "colored", transition: Flip })
                else{
                    localStorage.setItem('userToken', data.token)
                    setUser(data)
                }
            })
            .catch((e) => { toast.error('Ocurrió un error al intentar iniciar sesión.', { theme: "colored", transition: Flip }) })
            .finally(() => setLoading(false))
    }
    const showPassword = e => {
        e.preventDefault()
        setShowP(!showP)
    }

    return (
        <form onSubmit={doLogin}>
            <p className="center">Llena el formulario para ingresar a nuestro portal.</p>
            <div>
                <label htmlFor="user">Correo electrónico</label>
                <input type="email" name="email" placeholder="Correo electrónico" onChange={updateField} required />
            </div>
            <div>
                <label htmlFor="pass">Contraseña</label>
                <span tabIndex="-1" className="button field-icon-button" onClick={showPassword}><span className="material-icons">{showP ? 'visibility' : 'visibility_off'}</span></span>
                <input type={showP ? 'text' : 'password'} name="pass" placeholder="Contraseña" onChange={updateField} required />
            </div>
            <Link className="button recover" to="/recuperar-contrasena/">¿Olvidaste tu contraseña?</Link>
            <button type="submit">Iniciar sesión</button>
        </form>
    )
}
export default LoginForm