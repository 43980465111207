import { useParams } from 'react-router-dom'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../../AppContext'
import { appointmentTypes } from '../../layout/Names'
import { useContext, useState, useEffect } from 'react'

const ViewAppointment = () => {
    const { id } = useParams()
    const [ post, setPost ] = useState(null)
    const { user, setLoading, hostBase } = useContext(AppContext)
    
    useEffect(() => {
        if(post === null && id){
            setLoading(true)
            const token = localStorage.getItem('userToken')
            fetch(`${hostBase}/api/appointment/get/${id}/?token=${token}`)
                .then(res => res.json())
                .then((data) => {
                    if(data.length !== 0)
                        setPost(data)
                    else
                        toast.error('No se encontró información para esta cita.', { theme: "colored", transition: Flip })
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer la información de la cita.', { theme: "colored", transition: Flip }) })
                .finally(() => setLoading(false))
        }
        if(post !== null && !id)
            setPost(null)
    }, [])
    
    if(post === null)
        return <p>No tiene permisos para consultar la información solicitada.</p>
    return (
        <form>
            <h2 className={(post?.cancelled == 1 && 'red') || ''}>{(post?.cancelled == 1 && 'Cita cancelada') || 'Información de la cita'}.</h2>
            <div>
                <label htmlFor="type">Tipo de cita</label>
                <input type="text" name="type" value={appointmentTypes[post?.type] || ''} readOnly disabled />
            </div>
            <div>
                <label htmlFor="point">Sede</label>
                <input type="text" name="point" value={post?.point_name || ''} readOnly disabled />
            </div>
            <div className="datetime">
                <label htmlFor="date">Fecha</label>
                <input type="date" name="date" value={post?.date || ''} readOnly disabled />
                <input type="time" name="time" value={post?.time || ''} readOnly disabled />
            </div>
            <div>
                <label htmlFor="collector">Recolector asignado</label>
                <input type="text" name="collector" value={post?.collector_name || ''} readOnly disabled />
            </div>
            {post?.comments && <div className="full">
                <label htmlFor="comments">Observaciones</label>
                <textarea name="comments" value={post?.comments || ''} readOnly disabled></textarea>
            </div> || null}
        </form>
    )
}
export default ViewAppointment