import { useParams } from 'react-router-dom'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../../AppContext'
import { useContext, useState, useEffect } from 'react'

const CreateVault = () => {
    const { id } = useParams()
    const [ post, setPost ] = useState(null)
    const { setLoading, hostBase } = useContext(AppContext)
    
    const updateField = (e) => {
        let _post = {...post}
        _post[e.target.name] = e.target.value
        setPost(_post)
    }
    const doUpdate = (e) => {
        e.preventDefault()
        setLoading(true)
        let formData = new FormData()
        for(let key in post)
            formData.append(key, post[key])
        const token = localStorage.getItem('userToken')
        fetch(`${hostBase}/api/vault/update/?token=${token}`, { method: 'POST', body: formData })
            .then(res => res.json())
            .then((data) => {
                if(data?.error)
                    toast.error(data.error, { theme: "colored", transition: Flip })
                else{
                    let _post = {...post}
                    if(!id){
                        delete _post.name
                        delete _post.address
                    }
                    setPost(_post)
                    toast.success(data.state, { theme: "colored", transition: Flip })
                }
            })
            .catch((e) => { toast.error('Ocurrió un error al intentar crear la bodega.', { theme: "colored", transition: Flip }) })
            .finally(() => setLoading(false))
    }
    
    useEffect(() => {
        if(post === null && id){
            setLoading(true)
            const token = localStorage.getItem('userToken')
            fetch(`${hostBase}/api/vault/get/${id}/?token=${token}`)
                .then(res => res.json())
                .then((data) => {
                    if(data.length !== 0)
                        setPost(data)
                    else
                        toast.error('No se encontró información para esta bodega.', { theme: "colored", transition: Flip })
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer la información de la bodega.', { theme: "colored", transition: Flip }) })
                .finally(() => setLoading(false))
        }
        if(post !== null && !id)
            setPost(null)
    }, [])
    
    return (
        <form onSubmit={doUpdate}>
            <input type="hidden" name="vid" value={post?.vid || ''} />
            <h2>{id ? 'Modificación' : 'Creación'} de bodega.</h2>
            <div>
                <label htmlFor="name">Nombre</label>
                <input type="text" name="name" value={post?.name || ''} onChange={updateField} required />
            </div>
            <div>
                <label htmlFor="address">Dirección</label>
                <input type="text" name="address" value={post?.address || ''} onChange={updateField} required />
            </div>
            <button type="submit">{id ? 'Modificar' : 'Crear'} bodega</button>
        </form>
    )
}
export default CreateVault