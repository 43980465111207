import logo from '../../img/bolfit.svg'
import { AppContext } from '../AppContext'
import { useState, useEffect, useContext } from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'

const NavMenu = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const [ isOpen, setIsOpen ] = useState(false)
	const [ prevPage, setPrevPage ] = useState(false)
	const { user, hostBase } = useContext(AppContext)

	const toggleMenu = (e) => {
		e.preventDefault()
		setIsOpen(!isOpen)
	}
	const goBack = (e) => {
		e.preventDefault()
		navigate(['terminos-y-condiciones'].some(p => location.pathname.includes(p)) ? -2 : -1)
	}

	useEffect(() => {
		setIsOpen(false)
		setPrevPage(location.key != 'default' ? location : false)
	}, [location])

	return <header>
		{prevPage ? <a className="go-back" onClick={goBack}><span className="material-icons">arrow_back</span></a> : <></>}
		<div className="center"><Link to="/" className="logo"><img src={logo} alt="Bolfit" /></Link></div>
		{user && <>
			<a href="#" className={'menu-button '+isOpen.toString()} onClick={toggleMenu}><span></span></a>
			{!['terminos-y-condiciones'].some(p => location.pathname.includes(p)) ? <>
				<Link className="profile" to="/perfil/"><span className="material-icons">account_circle</span> {user.name}</Link>
				{user?.type > 1 ? <nav className={isOpen.toString()}>
					<NavLink to="/recolecciones/">Recolecciones</NavLink>
					{user.type == 2 ? <NavLink to="/clientes/">Clientes</NavLink> : <NavLink to="/sedes/">Sedes</NavLink>}
					<NavLink to="/cerrar-sesion/">Cerrar sesión</NavLink>
				</nav> : <nav className={isOpen.toString()}>
					<NavLink to="/">Inicio</NavLink>
					<NavLink to="/recolecciones/">Recolecciones</NavLink>
					<NavLink to="/administradores/">Administradores</NavLink>
					<NavLink to="/recolectores/">Recolectores</NavLink>
					<NavLink to="/clientes/">Clientes</NavLink>
					<NavLink to="/comerciales/">Comerciales</NavLink>
					<NavLink to="/bodegas/">Bodegas</NavLink>
					<NavLink to="/cerrar-sesion/">Cerrar sesión</NavLink>
				</nav>}
			</> : null}
		</>}
	</header>
}
export default NavMenu