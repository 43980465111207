import { toast, Flip } from 'react-toastify'
import { AppContext } from '../../AppContext'
import { Link, useParams } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'
import { siNo, userStatus, clientTypes } from '../../layout/Names'

const ViewClient = () => {
	const { id } = useParams()
	const [ post, setPost ] = useState(null)
	const [ agents, setAgents ] = useState(null)
	const [ vaults, setVaults ] = useState(null)
	const { user, setLoading, hostBase } = useContext(AppContext)
	const [ canEdit, setCanEdit ] = useState(id ? false : true)
	
	const updateField = (e) => {
		let _post = {...post}
		_post[e.target.name] = e.target.value
		if(!_post['trap'])
			_post['trap'] = 0
		setPost(_post)
	}
	const doUpdate = (e) => {
		e.preventDefault()
		setLoading(true)
		let formData = new FormData()
		for(let key in post)
			formData.append(key, post[key])
		const token = localStorage.getItem('userToken')
		fetch(`${hostBase}/api/client/update/?token=${token}`, { method: 'POST', body: formData })
			.then(res => res.json())
			.then((data) => {
				if(data?.error)
					toast.error(data.error, { theme: "colored", transition: Flip })
				else{
					let _post = !id ? {} : {...post}
					setPost(_post)
					toast.success(data.state, { theme: "colored", transition: Flip })
				}
			})
			.catch((e) => { toast.error('Ocurrió un error al intentar crear el cliente.', { theme: "colored", transition: Flip }) })
			.finally(() => setLoading(false))
	}
	const sendMail = (e) => {
		e.preventDefault()
		setLoading(true)
		let formData = new FormData()
		formData.append('uid', post?.uid)
		const token = localStorage.getItem('userToken')
		fetch(`${hostBase}/api/client/created/?token=${token}`, { method: 'POST', body: formData })
			.then(res => res.json())
			.then((data) => {
				if(data?.error)
					toast.error(data.error, { theme: "colored", transition: Flip })
				else
					toast.success(data.state, { theme: "colored", transition: Flip })
			})
			.catch((e) => { toast.error('Ocurrió un error al intentar enviar el mensaje al cliente.', { theme: "colored", transition: Flip }) })
			.finally(() => setLoading(false))
	}
	
	useEffect(() => {
		const token = localStorage.getItem('userToken')
		if(agents === null){
			fetch(`${hostBase}/api/agent/list/?token=${token}`)
				.then(res => res.json())
				.then((data) => {
					if(data.length !== 0)
						setAgents(data)
					else
						toast.error('No se encontró ningún comercial.', { theme: "colored", transition: Flip })
				})
				.catch((e) => { toast.error('Ocurrió un error al intentar traer el listado de comerciales.', { theme: "colored", transition: Flip }) })
				.finally(() => setLoading(false))
		}
		if(vaults === null){
			fetch(`${hostBase}/api/vault/list/?token=${token}`)
				.then(res => res.json())
				.then((data) => {
					if(data.length !== 0)
						setVaults(data)
					else
						toast.error('No se encontró ningún cliente.', { theme: "colored", transition: Flip })
				})
				.catch((e) => { toast.error('Ocurrió un error al intentar traer el listado de bodegas.', { theme: "colored", transition: Flip }) })
				.finally(() => setLoading(false))
		}
		if(post === null && id){
			setLoading(true)
			const token = localStorage.getItem('userToken')
			fetch(`${hostBase}/api/client/get/${id}/?token=${token}`)
				.then(res => res.json())
				.then((data) => {
					if(data.length !== 0)
						setPost(data)
					else
						toast.error('No se encontró información para este cliente.', { theme: "colored", transition: Flip })
				})
				.catch((e) => { toast.error('Ocurrió un error al intentar traer la información del cliente.', { theme: "colored", transition: Flip }) })
				.finally(() => setLoading(false))
		}
		if(post !== null && !id)
			setPost(null)
	}, [])
	
	return <form onSubmit={doUpdate}>
		{user?.type < 2 && <>
			<input type="hidden" name="uid" value={post?.uid || ''} />
			<h2 className={id && 'min'}>{id ? 'Información' : 'Creación'} de cliente {id}.</h2>
			{id && <>
				<a className={'button min'+(canEdit ? ' hidden' : '')} onClick={sendMail} style={{margin: '0 0 6px auto'}}>Mensaje de creación</a>
				<a className={'button min'+(canEdit ? ' hidden' : '')} onClick={() => setCanEdit(true)} style={{margin: '0 0 6px 6px'}}>Editar cliente</a>
				<Link className="button min" to={`/sedes/${post?.uid}/`} style={{margin: '0 0 6px 6px'}}>Sedes</Link>
			</>}
			<div>
				<label htmlFor="client_type">Tipo de cliente</label>
				<select name="client_type" value={post?.client_type || ''} onChange={updateField} required readOnly={id} disabled={id}>
					<option value="">Seleccionar</option>
                    <option value="1">Empresas / Negocios (Comercial)</option>
                    <option value="2">Recolección comunitaria</option>
                    <option value="3">Contenedores públicos (Punto Limpio)</option>
                    <option value="4">Hogares privados</option>
				</select>
			</div>
			<div>
				<label htmlFor="status">Estado</label>
				<select name="status" value={post?.status || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit}>
					<option value="">Seleccionar</option>
					<option value="1">Activo</option>
					<option value="2">Inactivo</option>
				</select>
			</div>
			<div>
				<label htmlFor="name">Razón social</label>
				<input type="text" name="name" value={post?.name || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit} />
			</div>
			<div>
				<label htmlFor="nit">NIT <small>(sin código de verificación)</small></label>
				<input type="text" name="nit" value={post?.nit || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit} />
			</div>
			<div>
				<label htmlFor="email">Correo electrónico</label>
				<input type="email" name="email" value={post?.email || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit} />
			</div>
			<div>
				<label htmlFor="price">Precio negociado</label>
				<input type="number" name="price" value={post?.price || ''} placeholder="0" onChange={updateField} required disabled={!canEdit} readOnly={!canEdit} />
			</div>
			{vaults !== null && vaults?.length > 0 && <div>
				<label htmlFor="vault">Bodega</label>
				<select name="vault" value={post?.vault || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit}>
					<option value="">Seleccionar bodega</option>
					{vaults?.map((v) => {
						return <option key={v.vid} value={v.vid}>{v.name}</option>
					})}
				</select>
			</div>}
			{agents !== null && agents?.length > 0 && <div>
				<label htmlFor="agent">Comercial</label>
				<select name="agent" value={post?.agent || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit}>
					<option value="">Seleccionar comercial</option>
					{agents?.map((ag) => {
						return <option key={ag.agid} value={ag.agid}>{ag.name}</option>
					})}
				</select>
			</div>}
			{post?.points?.length !== 0 ? (
				<>
					<h3>Sede principal.</h3>
					<div>
						<label htmlFor="point_name">Nombre Comercial</label>
						<input type="text" name="point_name" value={post?.point_name || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit} />
					</div>
					<div>
						<label htmlFor="city">Municipio / Ciudad</label>
						<input type="text" name="city" value={post?.city || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit} />
					</div>
					<div>
						<label htmlFor="district">Barrio</label>
						<input type="text" name="district" value={post?.district || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit} />
					</div>
					<div>
						<label htmlFor="address">Dirección</label>
						<input type="text" name="address" value={post?.address || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit} />
					</div>
					<div>
						<label htmlFor="zip">Código postal</label>
						<input type="text" name="zip" value={post?.zip || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit} />
					</div>
					{post?.client_type == 3 ? <>
						<div>
							<label htmlFor="population">Densidad poblacional</label>
							<input type="number" name="population" value={post?.population || 0} onChange={updateField} disabled={!canEdit} readOnly={!canEdit} />
						</div>
					</> : null}
					<div>
						<label htmlFor="schedule">Horario</label>
						<input type="text" name="schedule" value={post?.schedule || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit} />
					</div>
					<div>
						<label htmlFor="contact">Persona de contacto</label>
						<input type="text" name="contact" value={post?.contact || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit} />
					</div>
					<div>
						<label htmlFor="point_email">Correo electrónico contacto</label>
						<input type="email" name="point_email" value={post?.point_email || ''} onChange={updateField} disabled={!canEdit} readOnly={!canEdit} />
					</div>
					<div>
						<label htmlFor="phone">Teléfono / Celular</label>
						<input type="text" name="phone" value={post?.phone || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit} />
					</div>
					<div>
						<label htmlFor="frequency">Frecuencia</label>
						<select name="frequency" value={post?.frequency || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit}>
							<option value="">Seleccionar frecuencia</option>
							<option value="Semanal">Semanal</option>
							<option value="Quincenal">Quincenal</option>
							<option value="Mensual">Mensual</option>
						</select>
					</div>
					{post?.client_type == 3 ? <>
						<div>
							<label htmlFor="containers">Número total de contenedores</label>
							<input type="number" name="containers" value={post?.containers || 0} onChange={updateField} disabled={!canEdit} readOnly={!canEdit} />
						</div>
						<div>
							<label htmlFor="volume">Volumen del contenedor</label>
							<input type="number" name="volume" value={post?.volume || 0} onChange={updateField} disabled={!canEdit} readOnly={!canEdit} />
						</div>
					</> : null}
					<div>
						<label htmlFor="payment">Forma de pago</label>
						<select name="payment" value={post?.payment || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit}>
							<option value="">Seleccionar forma de pago</option>
							<option value="Transferencia">Transferencia</option>
							<option value="Efectivo">Efectivo</option>
							<option value="Convenio">Convenio</option>
						</select>
					</div>
					<div>
						<label htmlFor="trap">¿Requiere trampa de grasa?</label>
						<input type="radio" name="trap" value={0} checked={post?.trap !== '1'} onChange={updateField} disabled={!canEdit} readOnly={!canEdit} /> No
						<input type="radio" name="trap" value={1} checked={post?.trap === '1'} onChange={updateField} disabled={!canEdit} readOnly={!canEdit} /> Si
					</div>
				</>
			) : ''}
			<button className={canEdit ? '' : 'hidden'} type="submit">{id ? 'Modificar' : 'Crear'} cliente</button>
		</> || <>
			<h2 className="min">Información del cliente</h2>
			<Link className="button min" to={`/sedes/${post?.uid}/`}>Sedes</Link>
			<div>
				<label htmlFor="client_type">Tipo de cliente</label>
				<input type="text" name="client_type" value={clientTypes[post?.client_type] || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="status">Estado</label>
				<input type="text" name="status" value={userStatus[post?.status] || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="name">Razón social</label>
				<input type="text" name="name" value={post?.name || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="nit">NIT</label>
				<input type="text" name="nit" value={post?.nit || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="email">Correo electrónico</label>
				<input type="email" name="email" value={post?.email || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="price">Precio negociado</label>
				<input type="number" name="price" value={post?.price || ''} placeholder="0" readOnly disabled />
			</div>
			<div>
				<label htmlFor="vault_name">Bodega</label>
				<input type="text" name="vault_name" value={post?.vault_name || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="agent_name">Comercial</label>
				<input type="text" name="agent_name" value={post?.agent_name || ''} readOnly disabled />
			</div>
			<h3>Sede principal.</h3>
			<div>
				<label htmlFor="city">Municipio / Ciudad</label>
				<input type="text" name="city" value={post?.city || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="district">Barrio</label>
				<input type="text" name="district" value={post?.district || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="address">Dirección</label>
				<input type="text" name="address" value={post?.address || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="zip">Código postal</label>
				<input type="text" name="zip" value={post?.zip || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="schedule">Horario</label>
				<input type="text" name="schedule" value={post?.schedule || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="contact">Persona de contacto</label>
				<input type="text" name="contact" value={post?.contact || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="point_email">Correo electrónico contacto</label>
				<input type="email" name="point_email" value={post?.point_email || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="phone">Teléfono / Celular</label>
				<input type="text" name="phone" value={post?.phone || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="frequency">Frecuencia</label>
				<input type="text" name="frequency" value={post?.frequency || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="payment">Forma de pago</label>
				<input type="text" name="payment" value={post?.payment || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="trap">¿Requiere trampa de grasa?</label>
				<input type="text" name="trap" value={siNo[post?.trap] || ''} readOnly disabled />
			</div>
		</>}
	</form>
}
export default ViewClient