import { toast, Flip } from 'react-toastify'
import { AppContext } from '../../AppContext'
import { Link, useParams } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'

const ListPoints = () => {
    const { id } = useParams()
    const [ points, setPoints ] = useState([])
    const [ filter, setFilter ] = useState({s: ''})
    const { user, setLoading, hostBase } = useContext(AppContext)
    const [ filteredPoints, setFilteredPoints ] = useState([])

    const updateField = (e) => {
        let _filter = {...filter}
        _filter[e.target.name] = e.target.value
        setFilter(_filter)
    }
    
    useEffect(() => {
        if(user !== null && points.length !== 0)
            setFilteredPoints(points.filter(p => ((p.name.toLowerCase().search(filter.s.toLowerCase()) !== -1 || p.city.toLowerCase().search(filter.s.toLowerCase()) !== -1 || p.address.toLowerCase().search(filter.s.toLowerCase()) !== -1 || p.contact.toLowerCase().search(filter.s.toLowerCase()) !== -1) && (filter.status == '' || p.status == filter.status))))
        else if(user !== null){
            setLoading(true)
            const clientID = id ? id+'/' : ''
            const token = localStorage.getItem('userToken')
            fetch(`${hostBase}/api/point/list/${clientID}?token=${token}`, { method: 'GET' })
                .then(res => res.json())
                .then((data) => {
                    if(data.length !== 0)
                        setPoints(data)
                    else
                        toast.error('No se encontró ningúna cita.', { theme: "colored", transition: Flip })
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer el listado de citas.', { theme: "colored", transition: Flip }) })
                .finally(() => setLoading(false))
        }
    }, [user, points, filter])
    
    return (
        <div className="pointList">
            <div className="filters">
                <input type="text" name="s" value={filter?.s || ''} placeholder="Buscar..." onChange={updateField} autoComplete="off" />
            </div>
            <h2>{points.length > 0 && points[0].client}</h2>
            {filteredPoints.length !== 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Ciudad</th>
                            <th>Dirección</th>
                            <th>Persona de contacto</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredPoints?.map((p) => {
                            return (
                                <tr key={p.pid}>
                                    <td>{p.name} - {p.pid}</td>
                                    <td>{p.city}</td>
                                    <td>{p.address}</td>
                                    <td>{p.contact}</td>
                                    <td width="250" className="btn">
                                        <Link className="button min w-120" to={`/sedes/consultar/${p.pid}/`}>Ver más</Link>
                                        <Link className="button min" to={`/recolecciones/cliente/${id}/sede/${p.pid}/`}>Recolecciones</Link>
                                        <Link className="button min w-120" to={`/sedes/certificados/${p.pid}/`}>Aprovechamientos</Link>
                                        {p.last == 1 && <a className="button min w-120" href={`${hostBase}/api/point/warrant/${p.pid}/last/?token=${localStorage.getItem('userToken')}`} target="_blank">Último Aprovechamiento</a> || null}
                                        {p.terms != null && <a className="button min w-120" href={`${hostBase}/api/point/warrant_terms/${p.pid}/?token=${localStorage.getItem('userToken')}`} target="_blank">Autodeclaración</a> || null}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            ) : <p>No se encontró ningúna sede.</p>}
            {id && user?.type < 2 && <Link to={`/sedes/crear/${id}/`} className="button">Crear nueva sede</Link>}
        </div>
    )
}
export default ListPoints