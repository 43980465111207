import { useParams } from 'react-router-dom'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../../AppContext'
import InputSearch from '../../layout/InputSearch'
import { useContext, useState, useEffect } from 'react'

const CreateAppointment = () => {
    const [ post, setPost ] = useState(null)
    const [ points, setPoints ] = useState([])
    const { user, hostBase } = useContext(AppContext)
    const { action, id, client, point } = useParams()
    const [ collectors, setCollectors ] = useState(null)
    
    const updateField = (e) => {
        let _post = {...post}
        _post[e.target.name] = e.target.value
        if(e.target.name == 'client'){
            setPoints([])
            if(e.target.data.uid){
                _post['client'] = e.target.data.uid
                _post['client_name'] = e.target.data.label
                const token = localStorage.getItem('userToken')
                fetch(`${hostBase}/api/point/list/${e.target.data.uid}/?token=${token}`)
                    .then(res => res.json())
                    .then((data) => {
                            if(data.length !== 0)
                                setPoints(data)
                            else
                                toast.error('No se encontraron sedes para este cliente.', { theme: "colored", transition: Flip })
                        })
                        .catch((e) => { toast.error('Ocurrió un error al intentar traer la información del cliente.', { theme: "colored", transition: Flip }) })
                        .finally(() => {})
            }
        }
        setPost(_post)
    }
    const doUpdate = (e) => {
        e.preventDefault()
        let formData = new FormData()
        formData.append('uid', user.uid)
        for(let key in post)
            formData.append(key, post[key])
        const token = localStorage.getItem('userToken')
        fetch(`${hostBase}/api/appointment/update/?token=${token}`, { method: 'POST', body: formData })
            .then(res => res.json())
            .then((data) => {
                if(data?.error)
                    toast.error(data.error, { theme: "colored", transition: Flip })
                else{
                    if(!id)
                        setPost({})
                    toast.success(data.state, { theme: "colored", transition: Flip })
                }
            })
            .catch((e) => { toast.error('Ocurrió un error al intentar crear la cita.', { theme: "colored", transition: Flip }) })
            .finally(() => {})
    }
    
    useEffect(() => {
        const token = localStorage.getItem('userToken')
        if(collectors === null){
            fetch(`${hostBase}/api/collector/list/?token=${token}`)
                .then(res => res.json())
                .then((data) => {
                    if(data.length !== 0)
                        setCollectors(data)
                    else
                        toast.error('No se encontró ningún recolector.', { theme: "colored", transition: Flip })
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer el listado de recolectores.', { theme: "colored", transition: Flip }) })
                .finally(() => {})
        }
        if(post === null && id){
            fetch(`${hostBase}/api/appointment/get/${id}/?token=${token}`)
                .then(res => res.json())
                .then((data) => {
                    if(data.length !== 0){
                        setPost(data)
                        setPoints(data.points)
                    }else
                        toast.error('No se encontró información para esta cita.', { theme: "colored", transition: Flip })
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer la información de la cita.', { theme: "colored", transition: Flip }) })
                .finally(() => {})
        }else if(post === null && client){
            fetch(`${hostBase}/api/point/list/${client}/?token=${token}`)
                .then(res => res.json())
                .then((data) => {
                    if(data.length !== 0){
                        let _post = {client: client, client_name: data[0].client}
                        if(point)
                            _post['point'] = point
                        setPost(_post)
                        setPoints(data)
                    }else
                        toast.error('No se encontraron sedes para este cliente.', { theme: "colored", transition: Flip })
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer la información del cliente.', { theme: "colored", transition: Flip }) })
                .finally(() => {})
        }
    }, [])
    
    return <form onSubmit={doUpdate}>
        <h2>{id ? 'Edición' : 'Creación'} de cita.</h2>
        <div>
            <label htmlFor="type">Tipo de cita</label>
            <select name="type" value={post?.type || ''} onChange={updateField} required>
                <option value="">Seleccionar tipo</option>
                <option value="1">Recolección</option>
                <option value="2">Trampa</option>
            </select>
        </div>
        <div>
            <label htmlFor="client">Cliente</label>
            <InputSearch type="text" name="client" value={post?.client_name || ''} placeholder="Buscar cliente..." onChange={updateField} required={true} search="/api/client/search/" />
            <input className="invisible-hidden" type="text" name="client" value={post?.client || ''} onChange={e => {}} required />
        </div>
        <div>
            <label htmlFor="point">Sede</label>
            <select name="point" value={post?.point || ''} onChange={updateField} required>
                <option value="">Seleccionar sede</option>
                {points.length > 0 && points.map((p) => {
                    return <option key={p.pid} value={p.pid}>{p.name}</option>
                })}
            </select>
        </div>
        <div className="datetime">
            <label htmlFor="date">Fecha</label>
            <input type="date" name="date" value={post?.date || ''} onChange={updateField} required />
            <input type="time" name="time" value={post?.time || ''} onChange={updateField} required />
        </div>
        <div>
            <label htmlFor="collector">Recolector asignado</label>
            <select name="collector" value={post?.collector || ''} onChange={updateField} required>
                <option value="">Seleccionar Recolector</option>
                {collectors?.map((c) => {
                    return <option key={c.uid} value={c.uid}>{c.name}</option>
                })}
            </select>
        </div>
        <button type="submit">{action == 'modificar' ? 'Modificar' : 'Crear'} Recolección</button>
    </form>
}
export default CreateAppointment