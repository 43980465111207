import { toast, Flip } from 'react-toastify'
import { AppContext } from '../../AppContext'
import SignatureCanvas from 'react-signature-canvas'
import { useParams, useNavigate } from 'react-router-dom'
import { useContext, useState, useEffect, Fragment, useRef } from 'react'

const Terms = () => {
    const navigate = useNavigate()
    const signatureRef = useRef({})
    const [ post, setPost ] = useState(null)
	const { client, appointment } = useParams()
    const { user, setLoading, hostBase } = useContext(AppContext)
    
    const clearSignature = () => {
        signatureRef.current.clear()
        updateField(false, false, true)
    }
    const saveSignature = () => {
        updateField(false, signatureRef.current.getTrimmedCanvas().toDataURL('image/jpg'))
    }
    const updateField = (e, signature = false, clear = false) => {
        let _post = {...post}
        if(clear)
            delete _post.signature
        else if(signature)
            _post.signature = signature
        else
            _post[e.target.name] = e.target.value
        setPost(_post)
    }
    const doUpdate = (e) => {
        e.preventDefault()
        setLoading(true)
        let formData = new FormData()
        for(let key in post)
            formData.append(key, post[key])
        const token = localStorage.getItem('userToken')
        fetch(`${hostBase}/api/client/terms/?token=${token}`, { method: 'POST', body: formData })
            .then(res => res.json())
            .then((data) => {
                if(data?.error){
                    console.log('Test 1')
                    toast.error(data.error, { theme: "colored", transition: Flip })
                }
                else{
                    console.log('Test 2')
                    toast.success(data.state, { theme: "colored", transition: Flip })
                    navigate(-1)
                }
            })
            .catch((e) => { toast.error('Ocurrió un error al intentar conectar con la base de datos.', { theme: "colored", transition: Flip }) })
            .finally(() => setLoading(false))
    }
    
    useEffect(() => {
        if(post === null){
            setLoading(true)
            const token = localStorage.getItem('userToken')
            fetch(`${hostBase}/api/client/get/${client}/?token=${token}`)
                .then(res => res.json())
                .then((data) => {
                    const returnurl = appointment ? 'completar/'+appointment+'/' : ''
                    if(data.length !== 0){
                        if(data.terms != null){
                            console.log('Test 3')
                            navigate(`/recolecciones/${returnurl}`)
                        }
                        else
                            setPost(data)
                    }else
                        toast.error('No se encontró información para esta sede.', { theme: "colored", transition: Flip })
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer la información de la sede.', { theme: "colored", transition: Flip }) })
                .finally(() => setLoading(false))
        }
    }, [user])
    
    return <form onSubmit={doUpdate}>
        <input type="hidden" name="uid" value={post?.uid || ''} />
        <div className="overflow full" style={{'display': 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
            <h2 className="center">Términos y condiciones de recolección</h2>
            <h3>Firmando esta autodeclaración, el signatario confirma lo siguiente:</h3>
            <p>1. El UCO hace referencia al aceite y las grasas de origen vegetal o animal utilizados para cocinar comida destinada al consumo humano. Las entregas de UCO que cubre esta autodeclaración constan íntegramente de UCO y no se mezclan con otros aceites o grasas que no cumplan con la definición de UCO.</p>
            <p>2. El UCO que cubre esta autodeclaración cumple con la definición de «residuo». Esto significa que el UCO es un material que el punto de origen desecha o pretende o tiene la obligación de desechar, y que el UCO no se ha modificado o contaminado intencionadamente para cumplir con esta definición.</p>
            <p>3. Hay disponible documentación de las cantidades de UCO entregadas.</p>
            <p>4. Se cumple con la legislación nacional aplicable en relación con la prevención y la gestión de residuos (por ejemplo, para el transporte, la supervisión, etc.).</p>
            <p>5. Los auditores de entes de certificación o de ISCC (podrán estar acompañados por un representante del punto de recogida) podrán examinar si lo indicado en esta autodeclaración es correcto in situ o poniéndose en contacto con el signatario (por ejemplo, por teléfono).</p>
            <p>6. La información contenida en esta autodeclaración podrá transmitirse y ser revisada por el ente de certificación del punto de recogida y por ISCC. Nota: El ente de certificación e ISCC mantienen la confidencialidad de todos los datos proporcionados en esta autodeclaración.</p>
            <p>Casillas adicionales:</p>
            <p>La cantidad de UCO producida por el punto de origen es de diez (10) o más toneladas métricas al mes.</p>
            <p>El UCO producido por el punto de origen es total o parcialmente de origen animal.</p>
            <p>Destinatario del UCO: Kolibrie Energy SA.</p>
            <p>Recolección a través de Bolfit y Ecotrading Worldwide CI.</p>
            <div>
                <label htmlFor="name"><b>Razón social</b></label>
                <input type="text" value={post?.name || ''} readOnly />
            </div>
            <div>
                <label htmlFor="country"><b>País</b></label>
                <input type="text" value="Colombia" readOnly />
            </div>
            <div>
                <label htmlFor="city"><b>Municipio</b></label>
                <input type="text" value={post?.city || ''} readOnly />
            </div>
            <div>
                <label htmlFor="district"><b>Barrio</b></label>
                <input type="text" value={post?.district || ''} readOnly />
            </div>
            <div>
                <label htmlFor="address"><b>Dirección</b></label>
                <input type="text" value={post?.address || ''} readOnly />
            </div>
            <div>
                <label htmlFor="address"><b>Nombre</b></label>
                <input type="text" value={post?.contact || ''} readOnly />
            </div>
            <div>
                <label htmlFor="address"><b>Teléfono</b></label>
                <input type="text" value={post?.phone || ''} readOnly />
            </div>
            <div>
                <label htmlFor="terms_update"><b>¿Produce más de 10 toneladas al año?</b></label>
                <select name="terms_update" value={post?.terms_update || 0} onChange={updateField} required>
                    <option value="0">No</option>
                    <option value="1">Sí</option>
                </select>
            </div>
            <div className="sigCanvas">
                <label htmlFor="signature"><b>Firma</b></label>
                <SignatureCanvas ref={signatureRef} canvasProps={{width: 360, height: 200}} onEnd={saveSignature} />
            </div>
        </div>
        <div style={{'display': 'flex', justifyContent: 'center', 'margin': '0 auto', 'gap': '10px', paddingRight: '40px'}}>
            <button type="button" onClick={clearSignature}>Limpiar firma</button>
            <button type="submit" disabled={!post?.signature}>Aceptar y continuar</button>
        </div>
    </form>
}
export default Terms