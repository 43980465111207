import Popup from '../../layout/Popup'
import { Link } from 'react-router-dom'
import { siNo } from '../../layout/Names'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../../AppContext'
import { useContext, useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

const ViewPoint = () => {
	const navigate = useNavigate()
	const { action, id } = useParams()
	const [ post, setPost ] = useState(null)
	const [ confirm, setConfirm ] = useState(false)
	const { user, setLoading, hostBase } = useContext(AppContext)
	const [ canEdit, setCanEdit ] = useState(action != 'crear' && id ? false : true)
	
	const confirmDeletion = e => {
		e.preventDefault()
		setConfirm({name: e.target.getAttribute('name')})
	}
	const cancellDeletion = e => {
		e.preventDefault()
		setConfirm(false)
	}
	const updateField = (e) => {
		let _post = {...post}
		if(action == 'crear')
			_post['uid'] = id
		_post[e.target.name] = e.target.value
		setPost(_post)
	}
	const deletePoint = e => {
		e.preventDefault()
		setLoading(true)
		let formData = new FormData()
		formData.append('pid', id)
		formData.append('uid', post?.uid)
		const token = localStorage.getItem('userToken')
		fetch(`${hostBase}/api/point/delete/?token=${token}`, { method: 'POST', body: formData })
			.then(res => res.json())
			.then((data) => {
				if(data?.error)
					toast.error(data.error, { theme: "colored", transition: Flip })
				else{
					toast.success(data.state, { theme: "colored", transition: Flip })
					navigate('/sedes/'+post?.uid+'/')
				}
			})
			.catch((e) => { toast.error('Ocurrió un error al intentar crear la sede.', { theme: "colored", transition: Flip }) })
			.finally(() => setLoading(false))
	}
	const doUpdate = (e) => {
		e.preventDefault()
		setLoading(true)
		let formData = new FormData()
		for(let key in post)
			formData.append(key, post[key])
		const token = localStorage.getItem('userToken')
		fetch(`${hostBase}/api/point/update/?token=${token}`, { method: 'POST', body: formData })
			.then(res => res.json())
			.then((data) => {
				if(data?.error)
					toast.error(data.error, { theme: "colored", transition: Flip })
				else{
					if(action === 'crear') setPost({})
					toast.success(data.state, { theme: "colored", transition: Flip })
				}
			})
			.catch((e) => { toast.error('Ocurrió un error al intentar crear la sede.', { theme: "colored", transition: Flip }) })
			.finally(() => setLoading(false))
	}
	
	useEffect(() => {
		if(post === null && action == 'consultar' && id){
			setLoading(true)
			const token = localStorage.getItem('userToken')
			fetch(`${hostBase}/api/point/get/${id}/?token=${token}`)
				.then(res => res.json())
				.then((data) => {
					if(data.length !== 0)
						setPost(data)
					else
						toast.error('No se encontró información para esta sede.', { theme: "colored", transition: Flip })
				})
				.catch((e) => { toast.error('Ocurrió un error al intentar traer la información de la sede.', { theme: "colored", transition: Flip }) })
				.finally(() => setLoading(false))
		}
		if(action == 'crear')
			setPost(null)
	}, [])
	
	if(user?.type > 1 && post === null)
		return <p>No tiene permisos para consultar la información solicitada.</p>
	return <form onSubmit={doUpdate}>
		<input type="hidden" name="uid" value={post?.uid || id} />
		<input type="hidden" name="pid" value={post?.pid || ''} />
		{(post !== null && user?.type > 1 && <>
			<h2 className="min">Información de la sede.</h2>
			{user?.type == 3 && <a className={'button min'+(canEdit ? ' hidden' : '')} onClick={() => setCanEdit(true)} style={{margin: '0 0 6px auto'}}>Editar sede</a> || ''}
			<Link className="button min" to={`/sedes/certificados/${post?.pid}/`}>Aprovechamiento</Link>
			<div>
				<label htmlFor="name">Nombre de la sede</label>
				<input type="text" value={post?.name || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="business">Razón social</label>
				<input type="text" value={post?.business || ''} placeholder={post?.businessClient || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="nit">NIT</label>
				<input type="text" value={post?.nit || ''} placeholder={post?.nit || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="city">Municipio / Ciudad</label>
				<input type="text" value={post?.city || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="district">Barrio</label>
				<input type="text" value={post?.district || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="address">Dirección</label>
				<input type="text" name="address" value={post?.address || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="zip">Código postal</label>
				<input type="text" name="zip" value={post?.zip || ''} readOnly disabled />
			</div>
			{post?.client_type == 3 ? <>
				<div>
					<label htmlFor="population">Densidad poblacional</label>
					<input type="text" name="population" value={post?.population || ''} readOnly disabled />
				</div>
			</> : null}
			<div>
				<label htmlFor="contact">Persona de contacto</label>
				<input type="text" name="contact" value={post?.contact || ''} onChange={user?.type == 3 && updateField} required={user?.type == 3} readOnly={user?.type == 2 || !canEdit} disabled={user?.type == 2 || !canEdit} />
			</div>
			<div>
				<label htmlFor="email">Correo electrónico</label>
				<input type="email" name="email" value={post?.email || ''} onChange={user?.type == 3 && updateField} required={user?.type == 3} readOnly={user?.type == 2 || !canEdit} disabled={user?.type == 2 || !canEdit} />
			</div>
			<div>
				<label htmlFor="phone">Teléfono / Celular</label>
				<input type="text" name="phone" value={post?.phone || ''} onChange={user?.type == 3 && updateField} required={user?.type == 3} readOnly={user?.type == 2 || !canEdit} disabled={user?.type == 2 || !canEdit} />
			</div>
			<div>
				<label htmlFor="price">Precio negociado</label>
				<input type="number" value={post?.price || ''} placeholder={post?.priceClient || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="schedule">Horario</label>
				<input type="text" value={post?.schedule || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="payment">Forma de pago</label>
				<input type="text" value={post?.payment || ''} readOnly disabled />
			</div>
			<div>
				<label htmlFor="frequency">Frecuencia</label>
				<input type="text" value={post?.frequency || ''} readOnly disabled />
			</div>
			{post?.client_type == 3 ? <>
				<div>
					<label htmlFor="containers">Número total de contenedores</label>
					<input type="number" value={post?.containers || ''} readOnly disabled />
				</div>
				<div>
					<label htmlFor="volume">Volumen del contenedor</label>
					<input type="number" value={post?.volume || ''} readOnly disabled />
				</div>
			</> : null}
			<div>
				<label htmlFor="trap">¿Requiere trampa de grasa?</label>
				<input type="text" value={siNo[post?.trap] || ''} readOnly disabled />
			</div>
			{user?.type == 3 && canEdit && <div>
				<label htmlFor="notes" className="invisible">Enviar</label>
				<button type="submit">Actualizar información</button>
			</div> || null}
		</>) || <>
			{confirm ? <Popup title="Eliminar sede" message={`Está seguro que desea eliminar la sede "${confirm.name}"`} action={deletePoint} cancel={cancellDeletion}></Popup> : <></>}
			<h2 className="min">{action == 'consultar' ? 'Modificación' : 'Creación'} de sede.</h2>
			{action == 'consultar' && <>
				<a className="button min red" onClick={confirmDeletion} name={post?.name} style={{margin: '0 0 6px auto'}}>Eliminar sede</a>
				<a className={'button min'+(canEdit ? ' hidden' : '')} onClick={() => setCanEdit(true)} style={{margin: '0 0 6px 6px'}}>Editar sede</a>
			</> || ''}
			<div style={{width: '100%'}}></div>
			<div>
				<label htmlFor="name">Nombre de la sede</label>
				<input type="text" name="name" value={post?.name || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit} />
			</div>
			<div>
				<label htmlFor="business">Razón social <small>(opcional)</small></label>
				<input type="text" name="business" value={post?.business || ''} placeholder={post?.businessClient || ''} onChange={updateField} disabled={!canEdit} readOnly={!canEdit} />
			</div>
			<div>
				<label htmlFor="nit">NIT <small>(opcional, sin código de verificación)</small></label>
				<input type="text" name="nit" value={post?.nit || ''} placeholder={post?.nitClient} onChange={updateField} disabled={!canEdit} readOnly={!canEdit} />
			</div>
			<div>
				<label htmlFor="city">Municipio / Ciudad</label>
				<input type="text" name="city" value={post?.city || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit} />
			</div>
			<div>
				<label htmlFor="district">Barrio</label>
				<input type="text" name="district" value={post?.district || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit} />
			</div>
			<div>
				<label htmlFor="address">Dirección</label>
				<input type="text" name="address" value={post?.address || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit} />
			</div>
			<div>
				<label htmlFor="zip">Código postal</label>
				<input type="text" name="zip" value={post?.zip || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit} />
			</div>
			{post?.client_type == 3 ? <>
				<div>
					<label htmlFor="population">Densidad poblacional</label>
					<input type="number" name="population" value={post?.population || 0} onChange={updateField} disabled={!canEdit} readOnly={!canEdit} />
				</div>
			</> : null}
			<div>
				<label htmlFor="contact">Persona de contacto</label>
				<input type="text" name="contact" value={post?.contact || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit} />
			</div>
			<div>
				<label htmlFor="email">Correo electrónico <small>(opcional)</small></label>
				<input type="email" name="email" value={post?.email || post?.emailClient || ''} onChange={updateField} disabled={!canEdit} readOnly={!canEdit} />
			</div>
			<div>
				<label htmlFor="phone">Teléfono / Celular</label>
				<input type="text" name="phone" value={post?.phone || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit} />
			</div>
			<div>
				<label htmlFor="schedule">Horario</label>
				<input type="text" name="schedule" value={post?.schedule || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit} />
			</div>
			<div>
				<label htmlFor="price">Precio negociado (opcional)</label>
				<input type="number" name="price" value={post?.price || ''} placeholder={post?.priceClient || ''} onChange={updateField} disabled={!canEdit} readOnly={!canEdit} />
			</div>
			<div>
				<label htmlFor="payment">Forma de pago</label>
				<select name="payment" value={post?.payment || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit}>
					<option value="">Seleccionar</option>
					<option value="Transferencia">Transferencia</option>
					<option value="Efectivo">Efectivo</option>
					<option value="Convenio">Convenio</option>
					<option value="Donación">Donación</option>
				</select>
			</div>
			<div>
				<label htmlFor="frequency">Frecuencia</label>
				<select name="frequency" value={post?.frequency || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit}>
					<option value="">Seleccionar</option>
					<option value="Semanal">Semanal</option>
					<option value="Quincenal">Quincenal</option>
					<option value="Mensual">Mensual</option>
					<option value="Bimensual">Bimensual</option>
					<option value="Trimestral">Trimestral</option>
				</select>
			</div>
			{post?.client_type == 3 ? <>
				<div>
					<label htmlFor="containers">Número total de contenedores</label>
					<input type="number" name="containers" value={post?.containers || 0} onChange={updateField} disabled={!canEdit} readOnly={!canEdit} />
				</div>
				<div>
					<label htmlFor="volume">Volumen del contenedor</label>
					<input type="number" name="volume" value={post?.volume || 0} onChange={updateField} disabled={!canEdit} readOnly={!canEdit} />
				</div>
			</> : null}
			<div>
				<label htmlFor="trap">¿Requiere trampa de grasa?</label>
				<input type="radio" name="trap" value={0} checked={post?.trap !== '1'} onChange={updateField} disabled={!canEdit} readOnly={!canEdit} /> No
				<input type="radio" name="trap" value={1} checked={post?.trap === '1'} onChange={updateField} disabled={!canEdit} readOnly={!canEdit} /> Si
			</div>
			<button className={canEdit ? '' : 'hidden'} type="submit">{action == 'modificar' ? 'Modificar' : 'Crear'} sede</button>
		</>}
	</form>
}
export default ViewPoint