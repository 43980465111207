import { AppContext } from '../AppContext'
import { toast, Flip } from 'react-toastify'
import { useState, useEffect, useContext } from 'react'

const InputSearch = ({ type, name, search, onChange, value = '', required = false, readonly = false, disabled = false, placeholder = '' }) => {
    let abort = null
    const [ post, setPost ] = useState(value)
    const [ results, setResults ] = useState([])
    const { setLoading, hostBase } = useContext(AppContext)

    const searchData = e => {
        setPost(e.target.value)
        if(e.target.value.length > 1){
            if(abort)
                abort.abort()
            abort = new AbortController()

            setLoading(true)
            let formData = new FormData()
            formData.append('s', e.target.value)
            const token = localStorage.getItem('userToken')
            fetch(`${hostBase}${search}?token=${token}`, { method: 'POST', body: formData })
                .then(res => res.json())
                .then((data) => {
                    if(data.error)
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    else
                        setResults(data)
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar buscar los clientes.', { theme: "colored", transition: Flip }) })
                .finally(() => setLoading(false))
        }else
            setResults([])
        onChange({target: {name, value: e.target.value, data: {}}})
    }
    const setData = (value, data) => {
        setPost(value)
        searchData({target: {value: value}})
        onChange({target: {name, value: value, data: data}})
    }

    useEffect(() => {
        if(!post && value || value === '')
            setPost(value)
    }, [value])

    return <>
        <input type={type} name={name} value={post} placeholder={placeholder} onChange={searchData} required={required} disabled={disabled} readOnly={readonly} autoComplete="off" />
        <div className="search-results">
            {results?.map((r, i) => <a key={i} onClick={() => setData(r.result, r)}>{r.label}</a>)}
        </div>
    </>
}
export default InputSearch