import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../../AppContext'
import { useContext, useState, useEffect } from 'react'

const ListConsolidated = () => {
    let _year = ''
    const { id } = useParams()
    const { user, setLoading, hostBase } = useContext(AppContext)
    const [ consolidated, setConsolidated ] = useState([])

    const monthName = (month) => {
        let objDate = new Date();
        objDate.setDate(1);
        objDate.setMonth(month-1);
        return objDate.toLocaleString('es-CO', { month: "long" });
    }
    
    useEffect(() => {
        if(user !== null && consolidated.length === 0){
            setLoading(true)
            const token = localStorage.getItem('userToken')
            fetch(`${hostBase}/api/point/warrant_list/${id}/?token=${token}`)
                .then(res => res.json())
                .then((data) => {
                    if(data.length !== 0)
                        setConsolidated(data)
                    else
                        toast.error('No se encontró ningún certificado.', { theme: "colored", transition: Flip })
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer el listado de citas.', { theme: "colored", transition: Flip }) })
                .finally(() => setLoading(false))
        }
    }, [user])

    return (
        <div className="pointList">
            <h2>Certificados de aprovechamiento</h2>
            {consolidated.length !== 0 ? <p>Encuenta a continuación los certificados de aprovechamiento para la sede <strong>{consolidated[0].name} ({consolidated[0].client})</strong> </p> : <p>No se encontraron certificados.</p>}
            {consolidated.length !== 0 ? consolidated.map((c, i) => {
                let _return = false
                if(_year != c.year){
                    _year = c.year
                    _return = true
                }
                return <span key={i}>{_return && <h3 style={{width: '100%'}}>{c.year}</h3> || ''}<a className="button min inline" href={`${hostBase}/api/point/warrant/${id}/${c.year}/${c.month}/?token=${localStorage.getItem('userToken')}`} target="_blank">{monthName(c.month)}</a></span>
            }) : null}
        </div>
    )
}
export default ListConsolidated