import Popup from '../../layout/Popup'
import DatePicker from 'react-datepicker'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../../AppContext'
import Pagination from '../../layout/Pagination'
import 'react-datepicker/dist/react-datepicker.css'
import { useContext, useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'

const ListAppointments = () => {
    const navigate = useNavigate()
    const [ pages, setPages ] = useState(1)
    const [ reload, setReload ] = useState(1)
    const [ confirm, setConfirm ] = useState(false)
    const { page = 1, client, point } = useParams()
    const [ filtering, setFiltering ] = useState(false)
    const [ appointments, setAppointments ] = useState([])
    const { user, hostBase, sendToPrint, dateFormat } = useContext(AppContext)
    const [ filter, setFilter ] = useState({s: '', from: '', to: '', type: '', status: 'p'})
    
    const updateField = e => {
        let _filter = {...filter}
        _filter[e.target.name] = e.target.value
        setFilter(_filter)
    }
    const confirmCancell = e => {
        e.preventDefault()
        setConfirm({id: e.target.getAttribute('aid'), info: e.target.getAttribute('info'), completed: e.target.getAttribute('completed')})
    }
    const cancellCancell = e => {
        e.preventDefault()
        setConfirm(false)
    }
    const cancellAppointment = (e, comments = false) => {
        e.preventDefault()
        if(confirm?.completed <= 0){
            let formData = new FormData()
            formData.append('aid', confirm.id)
            formData.append('comments', comments)
            const token = localStorage.getItem('userToken')
            fetch(`${hostBase}/api/appointment/cancell/?token=${token}`, { method: 'POST', body: formData })
                .then(res => res.json())
                .then((data) => {
                    if(data?.error)
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    else{
                        toast.success(data.state, { theme: "colored", transition: Flip })
                        setConfirm(false)
                        setFilter({...filter, checked: Math.floor(Date.now() / 1000)})
                    }
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar cancelar la cita.', { theme: "colored", transition: Flip }) })
                .finally(() => {})
        }
    }
    useEffect(() => {
        setFiltering(true)
        let formData = new FormData()
        const token = localStorage.getItem('userToken')
        formData.append('page', page)
        formData.append('s', filter.s)
        formData.append('type', filter?.type)
        formData.append('status', filter?.status)
        if(point)   formData.append('point', point)
        if(client)  formData.append('client', client)
        formData.append('to', dateFormat(filter?.to))
        formData.append('from', dateFormat(filter?.from))
        fetch(`${hostBase}/api/appointment/list/?token=${token}`, { method: 'POST', body: formData })
            .then(res => res.json())
            .then((data) => {
                setFiltering(false)
                setPages(data.pages)
                setAppointments(data.appointments)
                if(document.querySelector(".overflow-table tbody"))
                    document.querySelector(".overflow-table tbody").scrollTop = 0
                if(data?.appointments.length === 0)
                    toast.error('No se encontró ningúna cita.', { theme: "colored", transition: Flip })
            })
            .catch((e) => {
                setFiltering(false)
                toast.error('Ocurrió un error al intentar traer el listado de citas.', { theme: "colored", transition: Flip })
            })
            .finally(() => {})
    }, [page, user, reload])
    
    return (
        <div className="appointmentList">
            {confirm ? <Popup title="Cancelar recolección" message={`Está seguro que desea cancelar la recolección del día ${confirm.info}`} action={cancellAppointment} cancel={cancellCancell} button="Cancelar recolección" comments={true}></Popup> : <></>}
            <h2 style={{marginTop: 0, marginBottom: 0}}>Recolecciones</h2>
            <div className="filters" style={{float: 'none'}}>
                <input type="text" name="s" value={filter?.s || ''} placeholder="Buscar..." onChange={updateField} autoComplete="off" />
                {user?.type < 3 ? <DatePicker placeholderText="Rango de fechas" onChange={date => setFilter({...filter, from: date[0], to: date[1]})} selectsRange selected={filter?.from} startDate={filter?.from} endDate={filter?.to} /> : null}
                <select name="type" value={filter?.type || ''} onChange={updateField}>
                    <option value="">Todos los tipos</option>
                    <option value="1">Recolección</option>
                    <option value="2">Trampa</option>
                </select>
                <select name="status" value={filter?.status || ''} onChange={updateField} style={{marginRight: '3px'}}>
                    <option value="p">Pendiente</option>
                    <option value="c">Completado</option>
                    {user?.type != 3 && <option value="x">Cancelada</option> || null}
                    <option value="">Todos los estados</option>
                </select>
                <a className="button min" disabled={filtering} onClick={() => {page == 1 ? setReload(reload + 1) : navigate('/recolecciones/1/')}} style={{marginRight: '3px'}}>Filtrar</a>
                <form action={`${hostBase}/api/user/export/?type=appointments&token=${localStorage.getItem('userToken')}`} target="_blank" method="POST" style={{display: 'inline-block', marginRight: '3px'}}>
                    {Object.keys(filter).map(key => <input key={key} type="hidden" name={key} value={['from', 'to'].find(k => k == key) ? dateFormat(filter[key]) : filter[key]} />)}
                    {point ? <input type="hidden" name="point" value={point} /> : null}
                    {client ? <input type="hidden" name="client" value={client} /> : null}
                    <button type="submit" className="button min">Exportar</button>
                </form>
                {user?.type < 2 ? <Link to={`/recolecciones/crear/${client && client+'/' || ''}${point && point+'/' || ''}`} className="button min">Agendar</Link> : null }
            </div>
            { appointments.length !== 0 ? <>
                <table className="overflow-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Fecha</th>
                            <th>Hora</th>
                            {user?.type != 2 ? <th>Recolector</th> : null}
                            {user?.type != 3 ? <th>Cliente</th> : <th>Sede</th>}
                            {user?.type == 2 ? <>
                                <th>Dirección</th>
                                <th>Horario</th>
                            </> : null}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody style={{'--desktopHeight': 'calc(100vh - 460px)'}}>
                        { appointments.map((a) => {
                            return (
                                <tr key={a.aid} className={(user?.type < 2 && 'admin') || (user?.type == 2 && 'collector' || 'client')}>
                                    <td>{a.completed != 0 ? a.completed.padStart(6, "0") : '-'}</td>
                                    <td>{a.show_date?.split(' ')[0]}</td>
                                    <td>{a.show_date?.split(' ')[1]}</td>
                                    {user?.type != 2 ? <td>{a.collector}</td> : null}
                                    <td>{a.point+(user?.type != 3 ? ' ('+a.client+')' : '')}</td>
                                    {user?.type == 2 ? <>
                                        <td>{a.address}</td>
                                        <td>{a.schedule}</td>
                                    </> : null}
                                    <td className="btn">
                                        {user?.type < 2 && a?.cancelled != 1 && <Link className="button min gray w-120" to={`/recolecciones/${(a.completed > 0 ? 'completar/' : 'modificar/')+a.aid}`}>Modificar</Link>}
                                        {
                                            (a.completed > 0 && <>
                                                <a className="button min w-120" onClick={e => {e.preventDefault(); sendToPrint(`${hostBase}/api/appointment/receipt/${a.aid}/?token=${localStorage.getItem('userToken')}`); }}>Recibo</a>
                                                <a className="button min w-120" target="_blank" href={`${hostBase}/api/appointment/warrant/${a.aid}/?token=${localStorage.getItem('userToken')}`}>Certificado</a>
                                            </>) ||
                                            (a.cancelled == 1 && <Link className="button min red w-120" to={`/recolecciones/consultar/${a.aid}`}>Cancelada</Link>) ||
                                            ((user?.type == 2 || user?.type == 0) && <>
                                                <a className="button min red w-120" aid={a.aid} info={(a.completed_date ? a.completed_date?.split(' ')[0] : a.date)+' a las '+(a.completed_date ? a.completed_date?.split(' ')[1] : a.time)+' para el cliente '+a.point+' ('+a.client+')'} completed={a.completed} onClick={confirmCancell}>Cancelar</a>
                                                <Link className="button min gray w-120" to={`/recolecciones/completar/${a.aid}`}>Completar</Link>
                                            </>) ||
                                            <Link className="button min gray w-120" to={`/recolecciones/consultar/${a.aid}`}>Programada</Link>}
                                    </td>
                                </tr>
                            )
                        }) }
                    </tbody>
                </table>
                <Pagination pages={pages} url="recolecciones" />
            </> : <p>No se encontró ninguna cita.</p> }
        </div>
    )
}
export default ListAppointments