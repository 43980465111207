import { useParams } from 'react-router-dom'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../../AppContext'
import { siNo, userStatus } from '../../layout/Names'
import { useContext, useState, useEffect } from 'react'

const CreateClient = () => {
    const { id } = useParams()
    const [ post, setPost ] = useState(null)
    const [ showP, setShowP ] = useState(false)
    const { user, setLoading, hostBase } = useContext(AppContext)
    
    const getSignatureData = (file) => {
        setLoading(true)
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onerror = error => reject(error);
            reader.onload = () => resolve(reader.result);
        })
            .then(data => {
                let _post = {...post}
                _post['signature'] = data
                setPost(_post)
            })
            .catch((e) => { toast.error('Ocurrió un error al intentar subir el archivo.', { theme: "colored", transition: Flip }) })
            .finally(() => setLoading(false))
    }
    const updateField = (e) => {
        let _post = {...post}
        if(e.target.name === 'signature_file')
            getSignatureData(e.target.files[0])
        else
            _post[e.target.name] = e.target.value
        setPost(_post)
    }
    const doUpdate = (e) => {
        e.preventDefault()
        setLoading(true)
        let formData = new FormData()
        for(let key in post)
            formData.append(key, post[key])
        const token = localStorage.getItem('userToken')
        fetch(`${hostBase}/api/profile/update/?token=${token}`, { method: 'POST', body: formData })
            .then(res => res.json())
            .then((data) => {
                if(data?.error)
                    toast.error(data.error, { theme: "colored", transition: Flip })
                else
                    toast.success(data.state, { theme: "colored", transition: Flip })
            })
            .catch((e) => { toast.error('Ocurrió un error al intentar conectar con la base de datos.', { theme: "colored", transition: Flip }) })
            .finally(() => setLoading(false))
    }
    const showPassword = e => {
        e.preventDefault()
        setShowP(!showP)
    }
    
    useEffect(() => {
        const token = localStorage.getItem('userToken')
        if(post === null){
            setLoading(true)
            const token = localStorage.getItem('userToken')
            fetch(`${hostBase}/api/user/get/?token=${token}`)
                .then(res => res.json())
                .then((data) => {
                    if(data.length !== 0)
                        setPost(data)
                    else
                        toast.error('No se encontró información para este cliente.', { theme: "colored", transition: Flip })
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer la información del cliente.', { theme: "colored", transition: Flip }) })
                .finally(() => setLoading(false))
        }
    }, [])
    
    return (
        <>
            <form onSubmit={doUpdate}>
                <input type="hidden" name="uid" value={post?.uid || ''} />
                <h2>Mi perfil</h2>
                {(user?.type == 3 && <div>
                    <label htmlFor="client_type">Tipo de cliente</label>
                    <input type="text" name="client_type" value={post?.client_type || ''} readOnly disabled />
                </div>) || null}
                <div>
                    <label htmlFor="status">Estado</label>
                    <input type="text" name="status" value={userStatus[post?.status] || ''} readOnly disabled />
                </div>
                {(user?.type == 3 && <>
                    <div>
                        <label htmlFor="name">Razón social</label>
                        <input type="text" name="name" value={post?.name || ''} readOnly disabled />
                    </div>
                    <div>
                        <label htmlFor="nit">NIT</label>
                        <input type="text" name="nit" value={post?.nit || ''} readOnly disabled />
                    </div>
                </>) || <div>
                    <label htmlFor="name">Nombre</label>
                    <input type="text" name="name" value={post?.name || ''} readOnly disabled />
                </div>}
                <div>
                    <label htmlFor="email">Correo electrónico</label>
                    <input type="email" name="email" value={post?.email || ''} onChange={updateField} required />
                </div>
                <div>
                    <label htmlFor="pass">Contraseña</label>
                    <span tabIndex="-1" className="button field-icon-button" onClick={showPassword}><span className="material-icons">{showP ? 'visibility' : 'visibility_off'}</span></span>
                    <input type={showP ? 'text' : 'password'} name="pass" value={post?.pass || ''} onChange={updateField} />
                </div>
                {(user?.type == 3 && <>
                    <div>
                        <label htmlFor="vault_name">Bodega</label>
                        <input type="text" name="vault_name" value={post?.vault_name || ''} readOnly disabled />
                    </div>
                    <div>
                        <label htmlFor="agent_name">Comercial</label>
                        <input type="text" name="agent_name" value={post?.agent_name || ''} readOnly disabled />
                    </div>
                    <div>
                        <label htmlFor="price">Precio negociado</label>
                        <input type="number" name="price" value={post?.price || ''} readOnly disabled />
                    </div>
                    {(post?.points?.length !== 0 && <>
                        <h3>Sede principal.</h3>
                        <div>
                            <label htmlFor="city">Municipio / Ciudad</label>
                            <input type="text" name="city" value={post?.city || ''} readOnly disabled />
                        </div>
                        <div>
                            <label htmlFor="district">Barrio</label>
                            <input type="text" name="district" value={post?.district || ''} readOnly disabled />
                        </div>
                        <div>
                            <label htmlFor="address">Dirección</label>
                            <input type="text" name="address" value={post?.address || ''} readOnly disabled />
                        </div>
                        <div>
                            <label htmlFor="contact">Persona de contacto</label>
                            <input type="text" name="contact" value={post?.contact || ''} onChange={updateField} required />
                        </div>
                        <div>
                            <label htmlFor="point_email">Correo electrónico contacto</label>
                            <input type="email" name="point_email" value={post?.point_email || ''} onChange={updateField} required />
                        </div>
                        <div>
                            <label htmlFor="phone">Teléfono / Celular</label>
                            <input type="text" name="phone" value={post?.phone || ''} onChange={updateField} required />
                        </div>
                        <div>
                            <label htmlFor="schedule">Horario</label>
                            <input type="text" name="schedule" value={post?.schedule || ''} readOnly disabled />
                        </div>
                        <div>
                            <label htmlFor="payment">Forma de pago</label>
                            <input type="text" name="payment" value={post?.payment || ''} readOnly disabled />
                        </div>
                        <div>
                            <label htmlFor="frequency">Frecuencia</label>
                            <input type="text" name="frequency" value={post?.frequency || ''} readOnly disabled />
                        </div>
                        <div>
                            <label htmlFor="trap">¿Requiere trampa de grasa?</label>
                            <input type="text" name="trap" value={siNo[post?.trap] || ''} readOnly disabled />
                        </div>
                    </>) || null}
                </>) || (user?.type == 2 && <>
                    <div className="signatureField full">
                        <label htmlFor="signature_file">Firma</label>
                        <input type="file" name="signature_file" onChange={updateField} />
                    </div>
                    <div className="signature full">
                        <img className={'hide '+!post?.signature} src={post?.signature || ''} alt={`Firma ${post?.name}`} />
                        <input className="hide true" type="text" name="signature" defaultValue={post?.signature || ''} required />
                    </div>
                </>) || null}
                <button type="submit">Actualizar información</button>
            </form>
        </>
    )
}
export default CreateClient