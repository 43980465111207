import { AppContext } from './AppContext'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Logout = () => {
    const navigate = useNavigate()
    const { setUser, hostBase } = useContext(AppContext)

    useEffect(() => {
        setUser(null)
        navigate('/iniciar-sesion/')
        localStorage.setItem('userToken', '')
        localStorage.clear()
    }, [])
    
    return null
}
export default Logout