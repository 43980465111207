import { Link } from 'react-router-dom'
import { AppContext } from '../AppContext'
import { toast, Flip } from 'react-toastify'
import { useContext, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

const Recover = () => {
    const { token } = useParams()
    const navigate = useNavigate()
    const [ post, setPost ] = useState({})
    const [ showP, setShowP ] = useState(false)
    const { setLoading, hostBase } = useContext(AppContext)

    const updateField = (e) => {
        let _post = {...post}
        _post[e.target.name] = e.target.value
        if(e.target.name == 'pass')
            _post['token'] = token
        setPost(_post)
    }
    const doLogin = (e) => {
        e.preventDefault()
        setLoading(true)
        let formData = new FormData()
        for(let key in post)
            formData.append(key, post[key])
        formData.append('url', window.location.origin)
        fetch(`${hostBase}/api/user/recover/`, { method: 'POST', body: formData })
            .then(res => res.json())
            .then( (data) => {
                if(data.state == 'success'){
                    toast.success(token ? 'Su contraseña se ha actualizado satisfactoriamente.' : 'Le hemos enviado instrucciones de reestablecimiento a su correo electónico.', { theme: "colored", transition: Flip })
                    navigate('/iniciar-sesion/')
                }else
                    toast.error(data.state, { theme: "colored", transition: Flip })
            })
            .catch((e) => { toast.error('Ocurrió un error al intentar enviar la información.', { theme: "colored", transition: Flip }) })
            .finally(() => setLoading(false))
    }
    const showPassword = e => {
        e.preventDefault()
        setShowP(!showP)
    }

    return (
        <form onSubmit={doLogin}>
            <p className="center">Llena el formulario para recuperar tu cuenta.</p>
            {!token && (
                <div>
                    <label htmlFor="user">Correo electrónico</label>
                    <input type="email" name="email" placeholder="Correo electrónico" value={post?.email || ''} onChange={updateField} required />
                </div>
            ) || (
                <div>
                    <label htmlFor="pass">Contraseña</label>
                    <span tabIndex="-1" className="button field-icon-button" onClick={showPassword}><span className="material-icons">{showP ? 'visibility' : 'visibility_off'}</span></span>
                    <input type={showP ? 'text' : 'password'} name="pass" placeholder="Contraseña" value={post?.pass || ''} onChange={updateField} required />
                    <input type="hidden" name="token" value={token} />
                </div>
            )}
            <Link className="button gray recover m0" to="/iniciar-sesion/">Regresar</Link>
            <button className="m0" type="submit">{token ? 'Cambiar contraseña' : 'Recuperar cuenta'}</button>
        </form>
    )
}
export default Recover