export const siNo = {
    '0': 'No',
    '1': 'Si'
}
export const appointmentTypes = {
    '1': 'Recolección',
    '2': 'Trampa'
}
export const userStatus = {
    '1': 'Activo',
    '2': 'Inactivo'
}
export const clientTypes = {
    '1': 'Empresas / Negocios',
    '2': 'Recolección comunitaria',
    '3': 'Contenedores públicos',
    '4': 'Hogares privados'
}