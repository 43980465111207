import parse from 'html-react-parser'
import { useReactToPrint } from 'react-to-print'
import { toast, Flip } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import React, { useState, useEffect, createContext, useRef } from 'react'

export const AppContext = createContext()
export const AppProvider = ({ children }) => {
    const toPrint = useRef()
    const location = useLocation()
    const navigate = useNavigate()
    const { Provider } = AppContext
    const [ user, setUser ] = useState(null)
    const path = location.pathname.split('/')[1]
    const [ loading, setLoading ] = useState(true)
    const [ dataToPrint, setDataToPrint ] = useState('')
    const hostBase = 'https://app.reciclaconbolfit.com' // https://sherkant.com/proyectos/bolfitapi

    const dateFormat = date => {
        let _date = date ? new Date(date) : ''
        if(_date)
            _date = _date.getFullYear()+'-'+(_date.getMonth()+1).toString().padStart(2, '0')+'-'+_date.getDate().toString().padStart(2, '0')
        return _date
    }
    const printData = useReactToPrint({ pageStyle: `
@media print {
    html,
    body {
		font-size: 173%;
		margin: 0;
		size: auto;
		width: 100%;
    }
	p {
		line-height: 110%;
		margin-bottom: 5%;
	}
}`, content: () => toPrint.current, onAfterPrint: () => { setDataToPrint('') } })
    const sendToPrint = url => {
        // url = url.replace('receipt', 'receiptnew')
        // fetch(url)
        //     .then((res) => res.json())
        //     .then((data) => {
        //         if(data.state != undefined && data.state == 'success'){
        //             setDataToPrint(data.text)
        //             printData()
        //         }else
        //             throw new Error();
        //     })
        //     .catch((e) => { toast.error('Ocurrió un error al intentar imprimir el recibo.', { theme: "colored", transition: Flip }) })
        window.open(url,"_blank","noopener,noreferrer")
    }

    useEffect(() => {
        const token = localStorage.getItem('userToken')
        if(token === null){
            if(location.pathname != '/iniciar-sesion/' && !location.pathname.includes('recuperar-contrasena'))
                navigate('/iniciar-sesion/')
            setLoading(false)
        }else if(user === null){
            fetch(`${hostBase}/api/user/check/?token=${token}`)
                .then((res) => res.json())
                .then((data) => {
                    if(data.state != undefined && data.state == 'success'){
                        setUser(data.user)
                    }else
                        throw new Error();
                })
                .catch((e) => {
                    toast.error('Ocurrió un error al intentar conservar la sesión.', { theme: "colored", transition: Flip })
                    localStorage.setItem('userToken', '')
                    localStorage.clear()
                    setUser(null)
                    if(location.pathname != '/iniciar-sesion/')
                        navigate('/iniciar-sesion/')
                })
                .finally(() => setLoading(false))
        }else{
            setLoading(false)
            if(['iniciar-sesion', 'recuperar-contasena'].some(p => location.pathname.includes(p)))
                navigate('/')
            else if(user && !(+user.terms) && (user.uid == 1 || user.uid == 2))
                navigate('/terminos-y-condiciones/')
            // else if(['terminos-y-condiciones'].some(p => location.pathname.includes(p)))
            //     navigate('/recolecciones/')
            else if(location.pathname == '/' && user.type > 1)
                navigate('/recolecciones/')
            else if(['bodegas', 'comerciales', 'administradores', 'recolectores', 'recolecciones/crear', 'terminos-y-condiciones'].some(p => location.pathname.includes(p)) && user.type > 2)
                navigate('/recolecciones/')
            else if(['cliente'].some(p => location.pathname.includes(p)) && user.type == 3)
                navigate('/recolecciones/')
        }
    }, [user, location])

    return (
        <GoogleReCaptchaProvider reCaptchaKey="6Lc8mLIUAAAAAC02oj7pfzCypd52z2eO83mhMBID">
            <Provider value={{ loading, user, setLoading, setUser, path, hostBase, dateFormat, sendToPrint }}>
                <div ref={toPrint}>{parse(dataToPrint)}</div>
                {children}
            </Provider>
        </GoogleReCaptchaProvider>
    )
}
export default AppProvider