import { toast, Flip } from 'react-toastify'
import { AppContext } from '../../AppContext'
import { userStatus } from '../../layout/Names'
import Pagination from '../../layout/Pagination'
import { useContext, useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'

const ListClients = () => {
    const navigate = useNavigate()
    const { page = 1 } = useParams()
    const [ pages, setPages ] = useState(1)
    const [ users, setUsers ] = useState([])
    const [ reload, setReload ] = useState(1)
    const { user, hostBase } = useContext(AppContext)
    const [ filtering, setFiltering ] = useState(false)
    const [ filter, setFilter ] = useState({s: '', client_type: '', status: ''})

    const updateField = (e) => {
        let _filter = {...filter}
        _filter[e.target.name] = e.target.value
        navigate('/clientes/')
        setFilter(_filter)
    }
    
    useEffect(() => {
        setFiltering(true)
        let formData = new FormData()
        formData.append('page', page)
        formData.append('s', filter.s)
        formData.append('client_type', filter?.client_type)
        formData.append('status', filter?.status)
        const token = localStorage.getItem('userToken')
        fetch(`${hostBase}/api/client/list/?token=${token}`, { method: 'POST', body: formData })
            .then(res => res.json())
            .then((data) => {
                setFiltering(false)
                setPages(data.pages)
                setUsers(data.users)
                if(document.querySelector(".overflow-table tbody"))
                    document.querySelector(".overflow-table tbody").scrollTop = 0
                if(data.users.length === 0)
                    toast.error('No se encontró ningún cliente.', { theme: "colored", transition: Flip })
            })
            .catch((e) => {
                setFiltering(false)
                toast.error('Ocurrió un error al intentar traer el listado de clientes.', { theme: "colored", transition: Flip })
            })
            .finally(() => {})
    }, [page, user, reload])

    return (
        <div className="clientList">
            <div className="filters">
                <input type="text" name="s" value={filter?.s || ''} placeholder="Buscar..." onChange={updateField} autoComplete="off" onKeyDown={e => e.key === 'Enter' ? page == 1 ? setReload(reload + 1) : navigate('/clientes/1/') : ''} />
                <select name="client_type" value={filter?.client_type || ''} onChange={updateField}>
                    <option value="">Todos los tipos</option>
                    <option value="1">Empresas / Negocios (Comercial)</option>
                    <option value="2">Recolección comunitaria</option>
                    <option value="3">Contenedores públicos (Punto Limpio)</option>
                    <option value="4">Hogares privados</option>
                </select>
                <select name="status" value={filter?.status || ''} onChange={updateField} style={{marginRight: '6px'}}>
                    <option value="">Todos los estados</option>
                    <option value="1">Activo</option>
                    <option value="2">Inactivo</option>
                </select>
                <a className="button min" disabled={filtering} onClick={() => {page == 1 ? setReload(reload + 1) : navigate('/clientes/1/')}} style={{marginRight: '6px'}}>Filtrar</a>
                {user?.type < 2 ? <Link to="/clientes/crear/" className="button min">Crear nuevo cliente</Link> : null}
            </div>
            <h2>Clientes</h2>
            { users.length !== 0 ? <>
                <table className="overflow-table">
                    <thead>
                        <tr>
                            <th>Razón Social</th>
                            <th>NIT</th>
                            <th width="280">Correo electrónico</th>
                            <th>Estado</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody style={{'--desktopHeight': 'calc(100vh - 470px)'}}>
                        { users.map((u) => {
                            return (
                                <tr key={u.uid}>
                                    <td>{u.terms ? <><span className="material-icons" style={{fontSize: '18px', color: '#228b44'}}>task_alt</span><br /></> : ''}{u.name} ({u.nick})</td>
                                    <td>{u.nit}</td>
                                    <td width="280">{u.email}</td>
                                    <td>{userStatus[u.status]}</td>
                                    <td className="btn">
                                        <Link className="button min" to={`/sedes/${u.uid}/`}>Sedes</Link>
                                        <Link className="button min" to={`/clientes/consultar/${u.uid}`}>Ver más</Link>
                                        <Link className="button min" to={`/recolecciones/cliente/${u.uid}/`}>Recolecciones</Link>
                                    </td>
                                </tr>
                            )
                        }) }
                    </tbody>
                </table>
                <Pagination pages={pages} url="clientes" />
            </> : <p>No se encontró ningún cliente.</p> }
        </div>
    )
}
export default ListClients