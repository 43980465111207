import { Link } from 'react-router-dom'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../../AppContext'
import { useContext, useState, useEffect } from 'react'

const ListAgents = () => {
    const [ agents, setAgents ] = useState([])
    const { setLoading, hostBase } = useContext(AppContext)
    const [ filter, setFilter ] = useState({s: ''})
    const [ filteredAgents, setFilteredAgents ] = useState([])

    const updateField = (e) => {
        let _filter = {...filter}
        _filter[e.target.name] = e.target.value
        setFilter(_filter)
    }
    
    useEffect(() => {
        if(agents.length === 0){
            setLoading(true)
            const token = localStorage.getItem('userToken')
            fetch(`${hostBase}/api/agent/list/?token=${token}`)
                .then(res => res.json())
                .then((data) => {
                    if(data.length !== 0)
                        setAgents(data)
                    else
                        toast.error('No se encontró ningún comercial.', { theme: "colored", transition: Flip })
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer el listado de comerciales.', { theme: "colored", transition: Flip }) })
                .finally(() => setLoading(false))
        }else
            setFilteredAgents(agents.filter(a => ((a.name.toLowerCase().search(filter.s.toLowerCase()) !== -1 || a.email.toLowerCase().search(filter.s.toLowerCase()) !== -1))))
    }, [agents, filter])

    return (
        <div className="agentList">
            <div className="filters">
                <input type="text" name="s" value={filter?.s || ''} placeholder="Buscar..." onChange={updateField} autoComplete="off" />
            </div>
            <h2>Comerciales</h2>
            { filteredAgents.length !== 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Correo electrónico</th>
                            <th>Teléfono / Celular</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        { filteredAgents.map((ag) => {
                            return (
                                <tr key={ag.agid}>
                                    <td>{ag.name}</td>
                                    <td>{ag.email}</td>
                                    <td>{ag.phone}</td>
                                    <td className="btn"><Link className="button min" to={`/comerciales/modificar/${ag.agid}/`}>Ver comercial</Link></td>
                                </tr>
                            )
                        }) }
                    </tbody>
                </table>
            ) : <p>No se encontró ningún comercial.</p> }
            <Link to="/comerciales/crear/" className="button">Crear nuevo comercial</Link>
        </div>
    )
}
export default ListAgents