import Chart from 'chart.js/auto'
import { useEffect, useRef } from 'react'
import ChartDataLabels from 'chartjs-plugin-datalabels'

Chart.register(ChartDataLabels);

const Bar = ({data}) => {
    const canvas = useRef(null)
    
    useEffect(() => {
        const bar = new Chart(canvas.current, {
            data: data,
            type: 'bar',
            options: {
                responsive: true
            },
            plugins: {
                title: { display: false },
                legend: { position: 'top' },
                subtitle: { display: false }
            }
        })
        return () => {
            bar.destroy()
        }
    }, [data])

    return <canvas ref={canvas}></canvas>
}
export default Bar