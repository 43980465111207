import { Link } from 'react-router-dom'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../../AppContext'
import { useContext, useState, useEffect } from 'react'

const ListCollectors = () => {
    const [ users, setUsers ] = useState([])
    const { setLoading, hostBase } = useContext(AppContext)
    const [ filteredUsers, setFilteredUsers ] = useState([])
    const [ filter, setFilter ] = useState({s: '', status: ''})

    const updateField = (e) => {
        let _filter = {...filter}
        _filter[e.target.name] = e.target.value
        setFilter(_filter)
    }
    
    useEffect(() => {
        if(users.length === 0){
            setLoading(true)
            const token = localStorage.getItem('userToken')
            fetch(`${hostBase}/api/collector/list/?token=${token}`)
                .then(res => res.json())
                .then((data) => {
                    if(data.length !== 0)
                        setUsers(data)
                    else
                        toast.error('No se encontró ningún recolector.', { theme: "colored", transition: Flip })
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer el listado de recolectores.', { theme: "colored", transition: Flip }) })
                .finally(() => setLoading(false))
        }else if(users.length >= 0)
            setFilteredUsers(users.filter(u => ((u.name.toLowerCase().search(filter.s.toLowerCase()) !== -1 || u.email.toLowerCase().search(filter.s.toLowerCase()) !== -1) && (filter.status == '' || u.status == filter.status))))
    }, [users, filter])

    return (
        <div className="collectorList">
            <div className="filters">
                <input type="text" name="s" value={filter?.s || ''} placeholder="Buscar..." onChange={updateField} autoComplete="off" />
                <select name="status" value={filter?.status || ''} onChange={updateField}>
                    <option value="">Todos los estados</option>
                    <option value="Activo">Activo</option>
                    <option value="Inactivo">Inactivo</option>
                </select>
            </div>
            <h2>Recolectores</h2>
            { filteredUsers.length !== 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Correo electrónico</th>
                            <th>Teléfono</th>
                            <th>Estado</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        { filteredUsers.map((u) => {
                            return (
                                <tr key={u.uid}>
                                    <td>{u.name}</td>
                                    <td>{u.email}</td>
                                    <td>{u.phone}</td>
                                    <td>{u.status == 1 ? 'Activo' : 'Inactivo'}</td>
                                    <td className="btn"><Link className="button min" to={`/recolectores/editar/${u.uid}`}>Ver información</Link></td>
                                </tr>
                            )
                        }) }
                    </tbody>
                </table>
            ) : <p>No se encontró ningún recolector.</p> }
            <Link to="/recolectores/crear/" className="button">Crear nuevo recolector</Link>
        </div>
    )
}
export default ListCollectors