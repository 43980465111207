import { Link } from 'react-router-dom'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../../AppContext'
import { useContext, useState, useEffect } from 'react'

const ListVaults = () => {
    const [ vaults, setVaults ] = useState([])
    const { setLoading, hostBase } = useContext(AppContext)
    const [ filter, setFilter ] = useState({s: ''})
    const [ filteredVaults, setFilteredVaults ] = useState([])

    const updateField = (e) => {
        let _filter = {...filter}
        _filter[e.target.name] = e.target.value
        setFilter(_filter)
    }
    
    useEffect(() => {
        if(vaults.length === 0){
            setLoading(true)
            const token = localStorage.getItem('userToken')
            fetch(`${hostBase}/api/vault/list/?token=${token}`)
                .then(res => res.json())
                .then((data) => {
                    if(data.length !== 0)
                        setVaults(data)
                    else
                        toast.error('No se encontró ninguna bodega.', { theme: "colored", transition: Flip })
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer el listado de bodegas.', { theme: "colored", transition: Flip }) })
                .finally(() => setLoading(false))
        }else
            setFilteredVaults(vaults.filter(v => (v.name.toLowerCase().search(filter.s.toLowerCase()) !== -1 || v.address.toLowerCase().search(filter.s.toLowerCase()) !== -1)))
    }, [vaults, filter])

    return (
        <div className="vaultList">
            <div className="filters">
                <input type="text" name="s" value={filter?.s || ''} placeholder="Buscar..." onChange={updateField} autoComplete="off" />
            </div>
            <h2>Bodegas</h2>
            { filteredVaults.length !== 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Dirección</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        { filteredVaults.map((v) => {
                            return (
                                <tr key={v.vid}>
                                    <td>{v.name}</td>
                                    <td>{v.address}</td>
                                    <td className="btn"><Link className="button min" to={`/bodegas/modificar/${v.vid}/`}>Ver bodega</Link></td>
                                </tr>
                            )
                        }) }
                    </tbody>
                </table>
            ) : <p>No se encontró ninguna bodega.</p> }
            <Link to="/bodegas/crear/" className="button">Crear nueva bodega</Link>
        </div>
    )
}
export default ListVaults