import Popup from '../../layout/Popup'
import { Link } from 'react-router-dom'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../../AppContext'
import { useContext, useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

const CreateCollector = () => {
    const { id } = useParams()
	const navigate = useNavigate()
    const [ post, setPost ] = useState(null)
    const [ confirm, setConfirm ] = useState(false)
    const { setLoading, hostBase } = useContext(AppContext)
    const [ canEdit, setCanEdit ] = useState(id ? false : true)
    
	const confirmDeletion = e => {
		e.preventDefault()
		setConfirm({name: e.target.getAttribute('name')})
	}
	const cancellDeletion = e => {
		e.preventDefault()
		setConfirm(false)
	}
	const deleteCollector = e => {
		e.preventDefault()
		setLoading(true)
		let formData = new FormData()
		formData.append('cid', id)
		formData.append('uid', post?.uid)
		const token = localStorage.getItem('userToken')
		fetch(`${hostBase}/api/collector/delete/?token=${token}`, { method: 'POST', body: formData })
			.then(res => res.json())
			.then((data) => {
				if(data?.error)
					toast.error(data.error, { theme: "colored", transition: Flip })
				else{
					toast.success(data.state, { theme: "colored", transition: Flip })
					navigate('/recolectores/')
				}
			})
			.catch((e) => { toast.error('Ocurrió un error al intentar crear la sede.', { theme: "colored", transition: Flip }) })
			.finally(() => setLoading(false))
	}
    const getSignatureData = (file) => {
        setLoading(true)
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onerror = error => reject(error);
            reader.onload = () => resolve(reader.result);
        })
            .then(data => {
                let _post = {...post}
                _post['signature'] = data
                setPost(_post)
            })
            .catch((e) => { toast.error('Ocurrió un error al intentar subir el archivo.', { theme: "colored", transition: Flip }) })
            .finally(() => setLoading(false))
    }
    const updateField = (e) => {
        let _post = {...post}
        if(e.target.name === 'signature_file')
            getSignatureData(e.target.files[0])
        else
            _post[e.target.name] = e.target.value
        setPost(_post)
    }
    const doUpdate = (e) => {
        e.preventDefault()
        setLoading(true)
        let formData = new FormData()
        for(let key in post)
            formData.append(key, post[key])
        const token = localStorage.getItem('userToken')
        fetch(`${hostBase}/api/collector/update/?token=${token}`, { method: 'POST', body: formData })
            .then(res => res.json())
            .then(data => {
                if(data?.error)
                    toast.error(data.error, { theme: "colored", transition: Flip })
                else{
                    let _post = !id ? {} : {...post}
                    if(_post?.pass) delete _post.pass
                    setPost(_post)
                    toast.success(data.state, { theme: "colored", transition: Flip })
                }
            })
            .catch(e => { toast.error('Ocurrió un error al intentar crear el recolector.', { theme: "colored", transition: Flip }) })
            .finally(() => setLoading(false))
    }
    
    useEffect(() => {
        if(post === null && id){
            setLoading(true)
            const token = localStorage.getItem('userToken')
            fetch(`${hostBase}/api/collector/get/${id}/?token=${token}`)
                .then(res => res.json())
                .then((data) => {
                    if(data.length !== 0)
                        setPost(data)
                    else
                        toast.error('No se encontró información para este recolector.', { theme: "colored", transition: Flip })
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer la información del recolector.', { theme: "colored", transition: Flip }) })
                .finally(() => setLoading(false))
        }
        if(post !== null && !id)
            setPost(null)
    }, [])

    return <div className="collector">
        <form onSubmit={doUpdate}>
            {confirm ? <Popup title="Eliminar recolector" message={`Está seguro que desea eliminar el recolector "${confirm.name}"`} action={deleteCollector} cancel={cancellDeletion}></Popup> : <></>}
            <input type="hidden" name="uid" value={post?.uid || ''} />
            <h2 className={id && 'min' || ''}>{id ? 'Información' : 'Creación'} de recolector.</h2>
            {id && <>
                <a className={'button min red'+(canEdit ? ' hidden' : '')} onClick={confirmDeletion} name={post?.name} style={{margin: '0 0 6px auto'}}>Eliminar recolector</a>
                <a className={'button min'+(canEdit ? ' hidden' : '')} onClick={() => setCanEdit(true)} style={{margin: '0 6px 6px auto'}}>Editar recolector</a>
                <Link className="button min" to={`/recolecciones/${post?.uid}/`}>Recolecciones</Link>
            </> || ''}
            <div>
                <label htmlFor="name">Nombre completo</label>
                <input type="text" name="name" value={post?.name || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit} />
            </div>
            <div>
                <label htmlFor="email">Correo electrónico</label>
                <input type="email" name="email" value={post?.email || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit} />
            </div>
            <div>
                <label htmlFor="phone">Celular</label>
                <input type="text" name="phone" value={post?.phone || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit} />
            </div>
            <div>
                <label htmlFor="status">Estado</label>
                <select name="status" value={post?.status || ''} onChange={updateField} required disabled={!canEdit} readOnly={!canEdit}>
                    <option value="">Seleccionar</option>
                    <option value="1">Activo</option>
                    <option value="2">Inactivo</option>
                </select>
            </div>
            <div className={'signatureField'+(canEdit ? '' : ' hidden')}>
                <label htmlFor="signature_file">Firma</label>
                <input type="file" name="signature_file" onChange={updateField} disabled={!canEdit} readOnly={!canEdit} />
            </div>
            <div className="signature">
                <img className={'hide '+!post?.signature} src={post?.signature || ''} alt={`Firma ${post?.name}`} />
                <input className="hide true" type="text" name="signature" defaultValue={post?.signature || ''} required={!post?.signature} />
            </div>
            <button className={canEdit ? '' : 'hidden'} type="submit" disabled={!canEdit} readOnly={!canEdit}>{id ? 'Modificar' : 'Crear'} recolector</button>
        </form>
    </div>
}
export default CreateCollector